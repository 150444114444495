import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import {
  blog_data,
  blogsResponsive,
} from "../../../../Component/Desktop/Common/BlogArray/BLogArray";
import Footer from "../../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../../Component/Desktop/Common/NavBar/NavBar";
import MFooter from "../../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../../Component/Mobile/Common/MNavBar/MNavBar";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import "./../BlogsPage.scss";

const BlogBenefitsOfCar = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const current_blog_data = {
    id: "benefits-of-car-insurance-renewal",
    blogtitle: "Benefits of Car insurance renewal",
    img_url: "/images/blog10.jpg",
    main_desc: (
      <>
        <h6 className="mb-3">What do you need to renew your car insurance?</h6>
        <p className="mb-4">
          Car insurance renewal is a must for vehicle owners as it ensures
          continued protection against financial liabilities arising from
          accidents, theft, or damage of the vehicle. However, while choosing
          the right plan for you there are a lot of important factors that need
          to be considered. It is imperative to compare and check car insurance
          renewal price along with the benefits that are available.
        </p>
      </>
    ),
    blog_full_desc: (
      <>
        <h6 className="mb-3">
          What are the key factors to check during car insurance renewal?
        </h6>
        <h6 className="mb-3">1. Type of coverage</h6>
        <p className="mb-5">
          There are primarily two types of car insurance policies in India-
        </p>
        <p className="mb-3">
          Third-Party Liability Insurance: This is mandatory by law and covers
          damages to third-party property or injuries/death to third parties in
          an accident involving your vehicle.
        </p>
        <p className="mb-3">
          Comprehensive Insurance: This covers third-party liabilities and
          provides coverage for damages to your own vehicle due to accidents,
          theft, fire, natural disasters, etc.
        </p>
        <p className="mb-5">
          Assess your needs then decide if you want to switch from third-party
          to comprehensive coverage or vice versa during insurance policy
          renewal.
        </p>

        <h6 className="mb-3">2. Insured Declared Value (IDV) </h6>
        <p className="mb-5">
          The IDV is the maximum sum assured by the insurer in case of theft of
          the vehicle. It is calculated based on the current market value of the
          car after depreciation. Make sure the IDV offered during renewal has
          the accurate market value of your vehicle.
        </p>

        <h6 className="mb-3">3. No Claim Bonus (NCB)</h6>
        <p className="mb-5">
          No Claim Bonus is a discount on the premium for not making any claims
          during the policy period. Check the NCB percentage applied to your
          renewal premium. If you have not made any claims, ensure the NCB is
          correctly applied. If you are switching insurers, make sure the new
          insurer considers your accumulated NCB.
        </p>
        <h6 className="mb-3">4. Add-on covers</h6>
        <p className="mb-3">
          Add-on covers enhance the protection provided by the base policy.
          Common add-ons include:
        </p>
        <ul className="mb-3">
          <li>
            - Zero Depreciation Cover: This add-on ensures you get the full
            claim amount without any deduction for depreciation on parts.
          </li>
          <li>
            - Engine Protection Cover: Covers engine damage due to water
            ingression or oil leakage.
          </li>
          <li>
            - Roadside Assistance: Provides services like towing, fuel delivery,
            flat tire change, etc.
          </li>
          <li>
            - Personal Accident Cover: Offers compensation in case of accidental
            death or permanent disability.
          </li>
        </ul>
        <p className="mb-5">
          You can decide on these add-ons based on your driving habits and
          vehicle condition.
        </p>
        <h6 className="mb-3">5. Premium amount</h6>
        <p className="mb-5">
          Compare the premium quoted for renewal with other insurers. Get it
          checked through us. While a lower premium is attractive, ensure it
          does not compromise coverage quality.
        </p>
        <h6 className="mb-3">6. Claim settlement ratio</h6>
        <p className="mb-5">
          The claim settlement ratio indicates the percentage of claims settled
          by the insurer against the total claims received. A higher ratio
          suggests a reliable insurer. Check this ratio before renewing your
          policy to ensure hassle-free claim settlements.
        </p>
        <h6 className="mb-3">7. Network garages</h6>
        <p className="mb-5">
          Insurers have tie-ups with various garages where you can avail of
          cashless repair services. Check the list of network garages and ensure
          there are sufficient options available near you.
        </p>
        <h6 className="mb-3">8. Policy terms and conditions</h6>
        <p className="mb-5">
          Read the fine print carefully. Understand the inclusions, exclusions,
          and terms of the policy. Check on deductibles, which is the amount you
          need to pay on your own before the insurer covers the rest.
        </p>
        <h6 className="mb-3">9. Discounts and offers</h6>
        <p className="mb-5">
          Look for any discounts or offers available during renewal. Some
          insurers offer loyalty discounts, bundling discounts (if you have
          multiple policies with the same insurer), or discounts for installing
          anti-theft devices in your car.
        </p>
        <h6 className="mb-3">10. Renewal timeliness</h6>
        <p className="mb-5">
          Ensure you renew your policy before the expiry date. Delays can lead
          to a lapse in coverage, and you might need to undergo vehicle
          inspection again, potentially increasing your premium.
        </p>
        <h6 className="mb-3">Conclusion</h6>
        <p className="mb-5">
          Renewing car insurance in India requires careful consideration of
          various factors to ensure you get the best possible coverage at a
          reasonable cost. By evaluating the type of coverage, IDV, NCB,
          add-ons, premium, claim settlement ratio, network garages, policy
          terms, and available discounts, you can make an informed decision that
          provides adequate protection for your vehicle.
        </p>
      </>
    ),
  };

  return (
    <Box className="blogsWrapper">
      {isMobile ? <MNavBar /> : <NavBar />}
      <Box className="blogsdetail-sec">
        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}>
          <Grid xs={12}>
            <h2>{current_blog_data?.blogtitle}</h2>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              marginBottom={"24px"}>
              <p
                style={{
                  fontSize: "14px",
                  lineHeight: "normal",
                  textTransform: "uppercase",
                  fontWeight: "700",
                }}>
                Sep 30, 2024
              </p>
              <Box display={"flex"} gap={"8px"}>
                <span className="tag">Insurance</span>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}>
          <Grid md={6} marginBottom={"24px"}>
            <Box className="img-overlay">
              <Box className="img-bg">
                <img src={current_blog_data?.img_url} alt="" />
              </Box>
            </Box>
          </Grid>
          <Grid md={6} marginBottom={"24px"}>
            {current_blog_data?.main_desc}
          </Grid>
          <Grid xs={12} marginBottom={"24px"}>
            {current_blog_data?.blog_full_desc}
          </Grid>
        </Grid>
      </Box>
      <Box className="blogCard-sec mb-pr-0">
        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}>
          <Grid xs={12} marginBottom={"24px"} className="mb-pr-0">
            <h4>You May Also Like</h4>
          </Grid>
          <Grid xs={12} className="mb-pr-0">
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={blogsResponsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              arrows={false}
              customTransition="transform 0.7s ease-in-out"
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              partialVisible={true}>
              {blog_data.map((item, index) => (
                <Box>
                  <Box
                    className="blog-card"
                    sx={{
                      boxShadow: "0px 4px 16px rgba(0,0,0,0.08)",
                      borderRadius: "12px",
                      marginRight: "16px",
                    }}>
                    <img src={item?.img_url} alt="" />
                    <Box className="card-info">
                      <h5 className="mb-1">{item.blogtitle}</h5>
                      <p className="mb-3">{item.blog_desc}</p>
                      <Link
                        className="cont-read-link"
                        onClick={() => {
                          navigate(item?.id);
                          window.scrollTo(0, 0);
                        }}>
                        Continue reading
                      </Link>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Carousel>
          </Grid>
        </Grid>
      </Box>
      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default BlogBenefitsOfCar;
