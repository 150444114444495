import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import {
  blog_data,
  blogsResponsive,
} from "../../../../Component/Desktop/Common/BlogArray/BLogArray";
import Footer from "../../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../../Component/Desktop/Common/NavBar/NavBar";
import MFooter from "../../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../../Component/Mobile/Common/MNavBar/MNavBar";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import "./../BlogsPage.scss";

const BlogFactorToBeConsd = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const current_blog_data = {
    id: "factors-to-be-considered-while-buying-personal-accident-insurance-in-india",
    blogtitle:
      "Factors to be considered while buying personal accident insurance in India",
    img_url: "/images/blog1.webp",
    main_desc: (
      <>
        <p className="mb-4">
          Health insurance has become a vital aspect of our lives because a
          medical emergency might occur at any time. One such emergency that can
          occur without prior notice is an accident. Sadly, if the incidence is
          severe, the cost of therapy may be expensive. Furthermore, if a person
          sustains an injury that could leave them incapacitated, it may have an
          impact on their ability to work and the welfare of their family.
          Therefore, you can purchase a Personal Accident Insurance Policy to
          safeguard you and your family from such a circumstance.
        </p>
        <p>
          In the event of an accident that results in death, physical harm,
          temporary total disability, permanent total disability or permanent
          partial disability, personal accident insurance provides financial
          assistance to you and your family. The health insurance provider will
          provide the designated nominee with 100% of the sum assured in the
          case of death. Additionally, insurance providers offer benefits for
          disabilities resulting from accidents, such as loss of eyesight,
          speech, or limbs.
        </p>
      </>
    ),
    blog_full_desc: (
      <>
        <h4 className="mb-3">What is a personal accident insurance policy?</h4>
        <p className="mb-5">
          Personal accident insurance is a policy that protects the insured
          financially against medical uncertainty caused by accidents such as
          accidental death, bodily injuries, partial/total disability, permanent
          and temporary disabilities.
        </p>
        <h4 className="mb-3">
          What are the types of personal accident insurance policies?
        </h4>
        <p className="mb-5">
          There are two types of personal accident insurance policies:
        </p>
        <ul className="mb-5">
          <li>
            <span>Group Personal Accident Insurance:</span> Employers or
            business owners typically get group personal accident insurance to
            protect their staff. Based on the size of the business and the
            number of employees to be insured, insurance firms offer savings on
            the premium rates. Since this policy assures employees financial
            stability in the event of a workplace accident, group personal
            accident insurance is something that all employees and their
            employers welcome.{" "}
          </li>
          <li>
            <span>Individual Personal Accident Insurance:</span> Individual
            personal accident insurance is a type of health insurance that
            protects the policyholder from an unfortunate event of an accident.
            It covers incident-related disability, loss of bodily parts or sight
            and accidental death.
          </li>
        </ul>
        <h4 className="mb-3">
          What are the factors to be considered while choosing a personal
          accident insurance policy?
        </h4>
        <p className="mb-5">
          Below mentioned are the factors that one should consider before buying
          a personal accident insurance policy:
        </p>
        <ul className="mb-5">
          <li>
            <span>Coverage:</span> One of the most important things to consider
            when purchasing a personal accident insurance policy is the
            coverage. Make sure your personal accident insurance policy covers
            every accident scenario that could result from your regular
            activities. Make sure your policy provides worldwide coverage for
            accidents if you frequently travel abroad.
          </li>
          <li>
            <span>Premium Amount:</span> You must pay a premium to the insurance
            provider in order to receive ongoing insurance coverage. Always
            examine the policy's premium cost before purchasing it. The premium
            payment to be paid should be reasonable and within your means.
          </li>
          <li>
            <span>Sum Insured:</span> Always take into account the extent of
            coverage that provides you with the appropriate level of insurance.
            Your income and medical demands should be taken into account when
            choosing your Sum Insured.
          </li>
          <li>
            <span>Inclusion:</span> Check for events / conditions that are all
            covered / included under the policy. To ensure that you are always
            aware of the accident scenarios that are covered by your policy, you
            should carefully review the policy's provisions and terms and
            conditions.
          </li>
          <li>
            <span>Exclusion:</span> Exclusions are situations that the policy
            does not cover and for which the insurer will not pay compensation.
            Before purchasing the insurance policy, you should study the
            policy's provisions and terms and conditions.
          </li>
          <li>
            <span>Claim Settlement Process:</span> Always verify the insurer's
            claim resolution procedure to make sure it is quick and easy. You
            must adhere to a specified procedure and provide the relevant
            documentation in order to lodge a claim. Verify that you are aware
            of the insurer’s claim history.
          </li>
          <li>
            <span>Coverage Abroad:</span> Make sure there are no geographic
            boundaries in your accident insurance if you frequently travel
            outside of India. You must be covered both inside and outside of
            India by your accident insurance coverage.
          </li>
        </ul>

        <h4 className="mb-3">Conclusion </h4>
        <p className="mb-5">
          Accidents can financially deplete you and are incredibly unpredictable
          situations. Therefore, you must shield your loved ones from the
          consequences on their finances. Also think about how they would cope
          if you were rendered permanently incapacitated. You can be prepared
          for these kinds of unforeseen circumstances if you have the
          appropriate insurance coverage. Medical costs incurred as a result of
          an accident are only covered by a personal accident insurance policy.
          With{" "}
          <span style={{ fontWeight: "900" }}>
            Jio Insurance Broking Limited
          </span>{" "}
          choose the finest personal accident insurance covers to safeguard
          yourself and your loved ones based on the factors we outlined above.
        </p>
      </>
    ),
  };

  return (
    <Box className="blogsWrapper">
      {isMobile ? <MNavBar /> : <NavBar />}
      <Box className="blogsdetail-sec">
        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}>
          <Grid xs={12}>
            <h2>{current_blog_data?.blogtitle}</h2>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              marginBottom={"24px"}>
              <p
                style={{
                  fontSize: "14px",
                  lineHeight: "normal",
                  textTransform: "uppercase",
                  fontWeight: "700",
                }}>
                Sep 30, 2024
              </p>
              <Box display={"flex"} gap={"8px"}>
                <span className="tag">Insurance</span>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}>
          <Grid md={6} marginBottom={"24px"}>
            <Box className="img-overlay">
              <Box className="img-bg">
                <img src={current_blog_data?.img_url} alt="" />
              </Box>
            </Box>
          </Grid>
          <Grid md={6} marginBottom={"24px"}>
            {current_blog_data?.main_desc}
          </Grid>
          <Grid xs={12} marginBottom={"24px"}>
            {current_blog_data?.blog_full_desc}
          </Grid>
        </Grid>
      </Box>
      <Box className="blogCard-sec mb-pr-0">
        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}>
          <Grid xs={12} marginBottom={"24px"} className="mb-pr-0">
            <h4>You May Also Like</h4>
          </Grid>
          <Grid xs={12} className="mb-pr-0">
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={blogsResponsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              arrows={false}
              customTransition="transform 0.7s ease-in-out"
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              partialVisible={true}>
              {blog_data.map((item, index) => (
                <Box>
                  <Box
                    className="blog-card"
                    sx={{
                      boxShadow: "0px 4px 16px rgba(0,0,0,0.08)",
                      borderRadius: "12px",
                      marginRight: "16px",
                    }}>
                    <img src={item?.img_url} alt="" />
                    <Box className="card-info">
                      <h5 className="mb-1">{item.blogtitle}</h5>
                      <p className="mb-3">{item.blog_desc}</p>
                      <Link
                        className="cont-read-link"
                        onClick={() => {
                          navigate(item?.id);
                          window.scrollTo(0, 0);
                        }}>
                        Continue reading
                      </Link>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Carousel>
          </Grid>
        </Grid>
      </Box>
      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default BlogFactorToBeConsd;
