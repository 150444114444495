import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import {
  blog_data,
  blogsResponsive,
} from "../../../../Component/Desktop/Common/BlogArray/BLogArray";
import Footer from "../../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../../Component/Desktop/Common/NavBar/NavBar";
import MFooter from "../../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../../Component/Mobile/Common/MNavBar/MNavBar";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import "./../BlogsPage.scss";

const BlogULIP = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const current_blog_data = {
    id: "unit-linked-insurance-plan-(ulip)",
    blogtitle: "Unit Linked Insurance Plan (ULIP)",
    img_url: "/images/blog9.webp",
    main_desc: (
      <>
        <p className="mb-4">
          For a beginner investor, Unit linked Insurance Plan (ULIP) has both
          protection and investment benefits. ULIPs can be used for life
          insurance, retirement income and educational costs.{" "}
        </p>
        <p>
          When you pay premium in a ULIP, one portion of your investment goes
          into investment of fund and you are allocated units. These units have
          Net Asset Value (NAV) which is calculated on daily basis. As these
          units are linked to the market, their NAV increases and decreases as
          per market trends. You have options of selected different funds in
          ULIP which will help you to diversity your ULIP investments.{" "}
        </p>
      </>
    ),
    blog_full_desc: (
      <>
        <h6 className="mb-3">ULIP working </h6>
        <p className="mb-3">
          Once you play your premium in ULIP, after premium allocation charges
          the premium is allocated to different funds according to the ratio
          chosen by you and units are assigned. Depending on NAV (Net Asset
          Value) units are calculated and assigned.{" "}
        </p>
        <p className="mb-3">
          After assigning of units the insurance company deducts some charges
          from your fund. Below are list of charges:{" "}
        </p>
        <ul className="mb-5">
          <li style={{ listStyle: "none" }}>i. Mortality charges </li>
          <li style={{ listStyle: "none" }}>
            ii. Policy Administration charges{" "}
          </li>
          <li style={{ listStyle: "none" }}>iii. Fund Management charges </li>
        </ul>
        <p className="mb-3">
          After knowing charges, let us now know about available funds.
        </p>
        <p className="mb-3">
          ULIPs provide a range of fund options from which you can select based
          on your needs. This gives you the freedom to make investments in
          accordance with your risk appetite, investment objectives, time
          horizon depending on your goal, and budget.
        </p>
        <p className="mb-3">
          Below are the fund options provided by unit linked insurance plans:
        </p>
        <ul className="mb-5">
          <li>
            <span>Equity funds:</span> These are riskier investments. Your money
            is invested in company stocks through equity funds. Over an extended
            investment horizon, they may provide higher return. However, in
            comparison to debt and balanced funds, they can be volatile.{" "}
          </li>
          <li>
            <span>Debt funds:</span> These funds present relatively lower risk
            in comparison to equity funds and provide stable returns. Debt funds
            invest in fixed income instruments.
          </li>
          <li>
            <span>Balanced funds:</span> These funds are hybrid in nature as
            they invest in both stock and debt markets, so you can earn balanced
            returns. Balanced funds offer medium risk by combining equity funds
            and debt funds together. As the name suggests, balanced funds can
            help balance out the risk by compensating for the high risk of
            equity with the low risk of debt securities.
          </li>
        </ul>

        <h6 className="mb-3">Benefits of ULIPs </h6>
        <ul className="mb-5">
          <li>
            <span>Regular Savings: </span>The key to effective long-term
            financial planning is the practice of consistent, disciplined
            saving, which is what ULIPs encourage. You can profit from wealth
            creation for your loved ones by paying premiums on time.
          </li>
          <li>
            <span>Protection: </span> ULIPs provide Life Cover, to take care of
            your family secure in your absence.
          </li>
          <li>
            <span>Flexibility of Investment:</span>
            You will have flexibility and control of your money through below
            options: <br />
            1. Fund Switch – An option to move your invested premium between
            equity, balanced and debt funds <br />
            2. Premium Redirection – An option to invest your future premium in
            a different fund of your choice <br />
            3. Partial Withdrawal – An option that allows you to withdraw a part
            of your money <br />
            4. Top-up – An option to invest additional money to your existing
            fund value
          </li>
          <li>
            <span>Tax Benefits: </span>Under Section 80C of the Income Tax Act
            of 1961, investments in ULIPs are allowed for a deduction from
            taxable income of up to 1.5 lakh per year. In accordance with the
            terms outlined in Section 10(10D) of the Income Tax Act, the
            maturity proceeds of the ULIP are likewise exempt from taxation. The
            death benefit indicated in the ULIP policy will be paid out to the
            ULIP investor if he or she passes away within the term of the
            policy, and under Section 10(10D) of the Income Tax Act of 1961, the
            money received at death is tax-free. Additionally, switching between
            ULIP funds is tax-free.
          </li>
        </ul>
      </>
    ),
  };

  return (
    <Box className="blogsWrapper">
      {isMobile ? <MNavBar /> : <NavBar />}
      <Box className="blogsdetail-sec">
        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}>
          <Grid xs={12}>
            <h2>{current_blog_data?.blogtitle}</h2>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              marginBottom={"24px"}>
              <p
                style={{
                  fontSize: "14px",
                  lineHeight: "normal",
                  textTransform: "uppercase",
                  fontWeight: "700",
                }}>
                Sep 30, 2024
              </p>
              <Box display={"flex"} gap={"8px"}>
                <span className="tag">Insurance</span>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}>
          <Grid md={6} marginBottom={"24px"}>
            <Box className="img-overlay">
              <Box className="img-bg">
                <img src={current_blog_data?.img_url} alt="" />
              </Box>
            </Box>
          </Grid>
          <Grid md={6} marginBottom={"24px"}>
            {current_blog_data?.main_desc}
          </Grid>
          <Grid xs={12} marginBottom={"24px"}>
            {current_blog_data?.blog_full_desc}
          </Grid>
        </Grid>
      </Box>
      <Box className="blogCard-sec mb-pr-0">
        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}>
          <Grid xs={12} marginBottom={"24px"} className="mb-pr-0">
            <h4>You May Also Like</h4>
          </Grid>
          <Grid xs={12} className="mb-pr-0">
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={blogsResponsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              arrows={false}
              customTransition="transform 0.7s ease-in-out"
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              partialVisible={true}>
              {blog_data.map((item, index) => (
                <Box>
                  <Box
                    className="blog-card"
                    sx={{
                      boxShadow: "0px 4px 16px rgba(0,0,0,0.08)",
                      borderRadius: "12px",
                      marginRight: "16px",
                    }}>
                    <img src={item?.img_url} alt="" />
                    <Box className="card-info">
                      <h5 className="mb-1">{item.blogtitle}</h5>
                      <p className="mb-3">{item.blog_desc}</p>
                      <Link
                        className="cont-read-link"
                        onClick={() => {
                          navigate(item?.id);
                          window.scrollTo(0, 0);
                        }}>
                        Continue reading
                      </Link>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Carousel>
          </Grid>
        </Grid>
      </Box>
      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default BlogULIP;
