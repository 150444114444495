import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import RKTextField from "../../../../Component/Amaze/FieldTypes/RKTextField/RKTextField";
import { isEmpty } from "../../../../SupportingFiles/HelpingFunction";
import SelectDropdown from "../../../../Component/Amaze/FieldTypes/SelectDropdown/SelectDropdown";
import DatePicker from "../../../../Component/Amaze/FieldTypes/DatePicker/DatePicker";
import { TNonTermProposerData } from "../../../../types/TNonTermSlice";
import { useAppSelector } from "../../../../Store/hooks";
import "./../../../../SCSS/Amaze/CommonSCSS/ProposalForms.scss";
import NonTermFooter from "../../../../Component/Amaze/NonTerm/NonTermFooter/NonTermFooter";
import SearchDropdown from "../../../../Component/Amaze/FieldTypes/SearchDropdown/SearchDropdown";
const NonTermProposal = ({
  proposerFields,
  fieldsUpdateState,
  validateForm,
  loader,
}: {
  proposerFields: TNonTermProposerData;
  fieldsUpdateState: Function;
  validateForm: Function;
  loader: boolean;
}) => {
  const { DROPDOWN_DATA, ADD_FORM, QUOTE_LOADER, UPDATE_RESPONSE } =
    useAppSelector((state) => state.NonTerm);

  const qualification = [
    {
      label: "01",
      value: "12th Pass",
    },
    {
      label: "02",
      value: "High School",
    },
    {
      label: "03",
      value: "Post Graduate",
    },
    {
      label: "04",
      value: "Graduate",
    },
    {
      label: "05",
      value: "Professional",
    },
  ];


  const occupation =[
    {
      label: "07",
      value: "Salaried",
    },
    {
      label: "08",
      value: "Self-employed",
    },
  ]


  return (
    <Box>
      <h5 className="sectionTitle">Proposer Details</h5>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <h6>Proposer / Insured Details</h6>
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"Full Name"}
            validation_type="NAME"
            value={proposerFields.full_name.value}
            attrName={["full_name"]}
            value_update={fieldsUpdateState}
            warn_status={proposerFields?.full_name?.warning}
            error_message={
              isEmpty(proposerFields.full_name.value)
                ? "Enter Full Name"
                : "Enter Valid Full Name"
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={4}>
          <DatePicker
            class_name="inputField"
            title={"DOB"}
            value={
              UPDATE_RESPONSE?.dob ? UPDATE_RESPONSE?.dob : ADD_FORM.age.value
            }
            attrName={["dob"]}
            disabled={true}
            value_update={fieldsUpdateState}
            error_message="Enter DOB"
            warn_status={ADD_FORM.age.warning}
            default_date={new Date("1990-01-01")}
          />
        </Grid>
        <Grid xs={4}>
          <SelectDropdown
            class_name="inputField"
            title="Gender"
            value={ADD_FORM.gender.value}
            attrName={["gender"]}
            value_update={fieldsUpdateState}
            data={[
              { value: "Male", label: "M" },
              { value: "Female", label: "F" },
              // { value: "O", label: "Other" },
            ]}
            disabled={true}
            warn_status={proposerFields?.gender?.warning}
            error_message="Select Gender"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={4}>
          <RKTextField
            disabled
            class_name="inputField"
            title={"Mobile"}
            value={ADD_FORM.mobile.value}
            attrName={["mobile"]}
            value_update={fieldsUpdateState}
            warn_status={ADD_FORM.mobile.warning}
            max_length={10}
            validation_type="NUMBER"
            error_message={!ADD_FORM.mobile.value ? "Enter mobile number" : ""}
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"Email"}
            value={proposerFields.email.value}
            attrName={["email"]}
            value_update={fieldsUpdateState}
            warn_status={proposerFields?.email?.warning}
            error_message={
              isEmpty(proposerFields.email.value)
                ? "Enter Email"
                : "Enter Valid Email"
            }
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid xs={4}>
          <RKTextField
            class_name="inputField"
            title={"Pincode"}
            value={ADD_FORM.pincode.value}
            attrName={["pincode"]}
            value_update={fieldsUpdateState}
            warn_status={ADD_FORM.pincode.warning}
            validation_type="NUMBER"
            max_length={6}
            disabled={true}
            error_message={!ADD_FORM.pincode.warning ? "Enter Pincode" : ""}
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"Emergency Phone"}
            value={proposerFields.emergency_number.value}
            attrName={["emergency_number"]}
            value_update={fieldsUpdateState}
            warn_status={proposerFields?.emergency_number?.warning}
            max_length={10}
            validation_type="NUMBER"
            error_message={
              isEmpty(proposerFields.emergency_number.value)
                ? "Enter Emergency Phone Number "
                : "Enter Valid Emergency Phone Number"
            }
          />
        </Grid>
        <Grid xs={4}>
          <SelectDropdown
            class_name="inputField"
            title="Occupation"
            value={proposerFields?.occupation?.value}
            attrName={["occupation"]}
            value_update={fieldsUpdateState}
            // data={DROPDOWN_DATA.OCCUPATION_DATA}
            data={ADD_FORM?.gender.value === "M" ? occupation : DROPDOWN_DATA.OCCUPATION_DATA}
            warn_status={proposerFields?.occupation?.warning}
            error_message="Select Proposer Occupation"
          />
        </Grid>
        <Grid xs={4}>
          <SelectDropdown
            class_name="inputField"
            title="Qualification"
            value={proposerFields?.qualification?.value}
            attrName={["qualification"]}
            value_update={fieldsUpdateState}
            // data={DROPDOWN_DATA.QUALIFICATION_DATA}
            data={qualification}
            warn_status={proposerFields?.qualification?.warning}
            error_message="Select Proposer Qualification"
          />
        </Grid>
      </Grid>

      <NonTermFooter loader={QUOTE_LOADER} forward={validateForm} />
    </Box>
  );
};

export default NonTermProposal;
