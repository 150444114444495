import { useEffect, useState } from "react";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import NonTermRidersPage from "../../../../Page/Desktop/NonTerm/NonTermProposal/HDFC/NonTermRidersPage";
import { TDropdown } from "../../../../types/Common/TDropdown";
import { formatAmountToCurrency } from "../../../../SupportingFiles/HelpingFunction";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { NON_TERM_SERVICES } from "../../../../Services/NonTerm/NonTermFormService";
import { NonTermSlice } from "../../../../Store/Slice_Reducer/NonTerm/NonTermSlice";
import { toast } from "react-toastify";
import { THDFCRidersBenefitsResponse } from "../../../../types/NonTerm/TNonTermRidersBenifits";
import { DTONonTermHDFCRiderStatus } from "../../../../types/NonTerm/DTONonTermRidersStatus";
import { Box } from "@mui/system";

const NonTermRidersContainer = () => {
  const isMobile = useIsMobile();

  const dispatch = useAppDispatch();
  const [tf001Data, setTf001Data] = useState<TDropdown[]>([]);
  const [tf002Data, setTf002Data] = useState<TDropdown[]>([]);
  const [tf003Data, setTf003Data] = useState<TDropdown[]>([]);
  const [tf004Data, setTf004Data] = useState<TDropdown[]>([]);
  const [tf005Data, setTf005Data] = useState<TDropdown[]>([]);

  const {
    SELECTED_QUOTE_DATA,
    PROPOSER_DATA,
    ADD_FORM,
    RIDERS_BENEFITS,
    ADD_FORM_RESPONSE,
    QUOTE_FIELDS_DATA,
    QUOTE_LOADER,
  } = useAppSelector((state) => state.NonTerm);
  const [loaderTf001, setLoaderTf001] = useState<boolean>(false);
  const [loaderTf002, setLoaderTf002] = useState<boolean>(false);
  const [loaderTf003, setLoaderTf003] = useState<boolean>(false);
  const [loaderTf004, setLoaderTf004] = useState<boolean>(false);
  const [loaderTf005, setLoaderTf005] = useState<boolean>(false);

  useEffect(() => {
    GET_RIDERS_PREMIUM();
  }, [RIDERS_BENEFITS.local_value]);

  function removeSymbols(value: string) {
    return value.replace(/[^\d]/g, "");
  }

  const GET_RIDERS_PREMIUM = () => {
    // const onSuccess = (data: any) => {
    //   setLoaderTf001(false);
    //   setLoaderTf002(false);
    //   setLoaderTf003(false);
    //   setLoaderTf004(false);
    //   setLoaderTf005(false);
    //   const results = data.results;
    //   const error = results.error;
    //   const message = results.message;
    //   const response: TRidersBenefitsResponse = results.response;

    //   if (!error) {
    //     dispatch(NonTermSlice.actions.SET_RIDERS_BENEFITS_DATA(response));
    //   } else {
    //     // toast.error("There are some technical error. Please contact to admin.");
    //     console.log({ error: error, message: message });
    //   }
    // };

    const onSuccess = (data: any) => {
      dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      setLoaderTf001(false);
      setLoaderTf002(false);
      setLoaderTf003(false);
      setLoaderTf004(false);
      setLoaderTf005(false);
      const error = data.response?.premiumDetails?.error;
      const message = data.message;
      // const response: TRidersBenefitsResponse = data.response;
      const response: THDFCRidersBenefitsResponse =
        data.response.premiumDetails;
      if (!error) {
        dispatch(NonTermSlice.actions.SET_HDFC_RIDERS_BENEFITS_DATA(response));
      } else {
        dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
        toast.error("There are some technical error. Please contact to admin.");
        console.log({ error: error, message: message });
      }
    };

    const onError = (err: any) => {
      dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      setLoaderTf001(false);
      setLoaderTf002(false);
      setLoaderTf003(false);
      setLoaderTf004(false);
      setLoaderTf005(false);
      toast.error("There are some technical error. Please contact to admin.");
    };

    const data: DTONonTermHDFCRiderStatus = {
      product_ids: [SELECTED_QUOTE_DATA.productDetails.id],
      criticalIllnessSelected: RIDERS_BENEFITS.local_value.tf001Status
        ? "Yes"
        : "No",
      accidentalDeathSelected: RIDERS_BENEFITS.local_value.tf005Status
        ? "Yes"
        : "No",
      cancerCovSelected: RIDERS_BENEFITS.local_value.tf004Status ? "Yes" : "No",
      accidentalDisSelected: RIDERS_BENEFITS.local_value.tf002Status
        ? "Yes"
        : "No",
      criticalIllnessValue: RIDERS_BENEFITS.local_value.tf001Status
        ? removeSymbols(RIDERS_BENEFITS.local_value.tf001Value)
        : removeSymbols(RIDERS_BENEFITS.local_value.tf004Value),
      accidentalDisValue: removeSymbols(RIDERS_BENEFITS.local_value.tf002Value),
      accidentalDeathValue: RIDERS_BENEFITS.local_value.tf003Status
        ? removeSymbols(RIDERS_BENEFITS.local_value.tf003Value)
        : removeSymbols(RIDERS_BENEFITS.local_value.tf005Value),
      personalAccSelected: RIDERS_BENEFITS.local_value.tf003Status
        ? "Yes"
        : "No",
      quote_no: ADD_FORM_RESPONSE.quote_no,
    };
    dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));

    NON_TERM_SERVICES.NON_TERM_UPDATE_RIDERS_STATUS(onSuccess, onError, data);
  };

  useEffect(() => {
    const start_tf001 = 5000;
    const end_tf001 = Math.round(
      Number(SELECTED_QUOTE_DATA?.premiumDetails.risk_cover_cal)
    );

    let data_tf001: TDropdown[] = [];
    for (let i = start_tf001; i <= end_tf001; i += 5000) {
      data_tf001.push({ label: formatAmountToCurrency(i), value: `${i}` });
    }

    setTf001Data(data_tf001);

    const start_tf002 = 400000;
    const end_tf002 = Math.round(
      Number(SELECTED_QUOTE_DATA?.premiumDetails.risk_cover_cal)
    );

    let data_tf002: TDropdown[] = [];
    for (let i = start_tf002; i <= end_tf002; i += 100000) {
      data_tf002.push({ label: formatAmountToCurrency(i), value: `${i}` });
    }

    setTf002Data(data_tf002);

    const start_tf003 = 5000;
    const end_tf003 = Math.round(
      Number(SELECTED_QUOTE_DATA?.premiumDetails.risk_cover_cal)
    );

    let data_tf003: TDropdown[] = [];
    for (let i = start_tf003; i <= end_tf003; i += 5000) {
      data_tf003.push({ label: formatAmountToCurrency(i), value: `${i}` });
    }

    setTf003Data(data_tf003);

    const start_tf004 = 5000;
    const end_tf004 = Math.round(
      Number(SELECTED_QUOTE_DATA?.premiumDetails.risk_cover_cal)
    );

    let data_tf004: TDropdown[] = [];
    for (let i = start_tf004; i <= end_tf004; i += 5000) {
      data_tf004.push({ label: formatAmountToCurrency(i), value: `${i}` });
    }

    setTf004Data(data_tf004);

    const start_tf005 = 5000;
    const end_tf005 = Math.round(
      Number(SELECTED_QUOTE_DATA?.premiumDetails.risk_cover_cal)
    );

    let data_tf005: TDropdown[] = [];
    for (let i = start_tf005; i <= end_tf005; i += 5000) {
      data_tf005.push({ label: formatAmountToCurrency(i), value: `${i}` });
    }

    setTf005Data(data_tf005);
  }, [RIDERS_BENEFITS]);

  useEffect(() => {
    setLoaderTf001(true);
  }, [
    RIDERS_BENEFITS.local_value.tf001Status,
    RIDERS_BENEFITS.local_value.tf001Value,
  ]);

  useEffect(() => {
    setLoaderTf002(true);
  }, [
    RIDERS_BENEFITS.local_value.tf002Status,
    RIDERS_BENEFITS.local_value.tf002Value,
  ]);

  useEffect(() => {
    setLoaderTf003(true);
  }, [
    RIDERS_BENEFITS.local_value.tf003Status,
    RIDERS_BENEFITS.local_value.tf003Value,
  ]);

  useEffect(() => {
    setLoaderTf004(true);
  }, [
    RIDERS_BENEFITS.local_value.tf004Status,
    RIDERS_BENEFITS.local_value.tf004Value,
  ]);

  useEffect(() => {
    setLoaderTf005(true);
  }, [
    RIDERS_BENEFITS.local_value.tf005Status,
    RIDERS_BENEFITS.local_value.tf005Value,
  ]);

  return (
    <>
      {QUOTE_LOADER ? (
        <Box
          position="fixed"
          height="100%"
          width="100%"
          zIndex="991"
          top="0px"
          left="0px"
        />
      ) : null}
      {isMobile ? //   loaderTf004={loaderTf004} //   loaderTf003={loaderTf003} //   loaderTf002={loaderTf002} //   loaderTf001={loaderTf001} // <MNonTermRidersBenefits
      //   loaderTf005={loaderTf005}
      //   tf001Data={tf001Data}
      //   tf002Data={tf002Data}
      //   tf003Data={tf003Data}
      //   tf004Data={tf004Data}
      //   tf005Data={tf005Data}
      // />
      null : (
        <NonTermRidersPage
          loaderTf001={loaderTf001}
          loaderTf002={loaderTf002}
          loaderTf003={loaderTf003}
          loaderTf004={loaderTf004}
          loaderTf005={loaderTf005}
          tf001Data={tf001Data}
          tf002Data={tf002Data}
          tf003Data={tf003Data}
          tf004Data={tf004Data}
          tf005Data={tf005Data}
        />
      )}
    </>
  );
};

export default NonTermRidersContainer;
