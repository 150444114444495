import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CustomCheckbox from "../../../Component/InputFields/CustomCheckbox/CustomCheckbox";
import JDSButtons from "../../../Component/InputFields/JDSButtons/JDSButtons";
import SelectDropdown from "../../../Component/InputFields/SelectDropdown/SelectDropdown";
import TextInputField from "../../../Component/InputFields/TextInputField/TextInputField";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import { HOME_SERVICES } from "../../../Services/Home/HomeServices";
import {
  isEmpty,
  validateEmail,
  validateMobileNumber,
} from "../../../SupportingFiles/HelpingFunction";
import { COLORS } from "../../../SupportingFiles/colors";
import { toast } from "react-toastify";
import HelmetComponent from "../../../SupportingFiles/HelmetComponent";
import { pushDataLayer, sendEventToNativeApp } from "../../../utils/analytics";
import SearchSelectDropdown from "../../../Component/InputFields/SearchSelectDropdown/SearchSelectDropdown";
import { useNavigate } from "react-router-dom";
import { COMMON_ROUTES } from "../../../Router/Path/CommonRoutes";

function MContact() {
  const navigate = useNavigate();
  type TContactUs = {
    name: { value: string; warn_status: boolean };
    contact: { value: string; warn_status: boolean };
    email: { value: string; warn_status: boolean };
    lookingForIns: { value: string; warn_status: boolean };
  };
  const [contactformFields, setContactFormFields] = useState<TContactUs>({
    name: { value: "", warn_status: false },
    contact: { value: "", warn_status: false },
    email: { value: "", warn_status: false },
    lookingForIns: { value: "", warn_status: false },
  });
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 300 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const lookingfroInsuranceData = [
    {
      label: "Health Insurance",
      value: "Health Insurance",
    },
    {
      label: "Car Insurance",
      value: "Car Insurance",
    },
    {
      label: "Two-Wheeler Insurance",
      value: "Two-Wheeler Insurance",
    },
    {
      label: "Term Insurance",
      value: "Term Insurance",
    },
    {
      label: "Investment Plans",
      value: "Investment Plans",
    },
  ];

  const updateMasterState = (attrName: string, value: string) => {
    setContactFormFields((prev: any) => ({
      ...prev,
      [attrName]: {
        value: value,
        warn_status:
          attrName === "contact"
            ? !validateMobileNumber(value)
            : attrName === "email"
            ? !validateEmail(value)
            : isEmpty(value),
      },
    }));
  };

  const validate_form = () => {
    let data: TContactUs = { ...contactformFields };

    data = {
      ...data,
      contact: {
        ...data.contact,
        warn_status: !validateMobileNumber(data.contact.value),
      },
      email: {
        ...data.email,
        warn_status: !validateEmail(data.email.value),
      },
      lookingForIns: {
        ...data.lookingForIns,
        warn_status: isEmpty(data.lookingForIns.value),
      },
      name: {
        ...data.name,
        warn_status: isEmpty(data.name.value),
      },
    };
    setContactFormFields({ ...data });
    let hasError: boolean = false;
    if (
      data.contact.warn_status ||
      data.email.warn_status ||
      data.lookingForIns.warn_status ||
      data.name.warn_status
    ) {
      hasError = true;
    }
    if (!hasError) {
      UPLOAD_CONTACT_US_DATA(data);
    }
  };

  const UPLOAD_CONTACT_US_DATA = (data: TContactUs) => {
    const toastId = toast.loading("Submitting Data...");
    const onSuccess = (res: any) => {
      setContactFormFields({
        name: { value: "", warn_status: false },
        contact: { value: "", warn_status: false },
        email: { value: "", warn_status: false },
        lookingForIns: { value: "", warn_status: false },
      });
      toast.dismiss(toastId);
      toast.success(
        "Thank you for connecting with us, and we will get in touch with you shortly"
      );
    };
    const onError = (err: any) => {
      toast.error("Failed to submit data!");
    };

    pushDataLayer(
      "jioinsure_home_header",
      "contact",
      "submit",
      "Insure website"
    );
    const eventProps = {
      action: "contact",
      click: "submit",
      EntrySource: "Insure website",
    };
    sendEventToNativeApp("jioinsure_home_header", eventProps);
    HOME_SERVICES.CONTACT_US(onSuccess, onError, {
      email: data.email.value,
      username: data.name.value,
      number: data.contact.value,
      productName: data.lookingForIns.value,
    });
  };
  return (
    <>
      <HelmetComponent
        title="Contact details and Customer care details | JioInsure"
        description="Get the contact details, customer care details in case you require any assistance. You can write to us at customer.care@jioinsure.in for any help"
      />
      <Box sx={{ paddingTop: "64px" }} className="mhomeWrapper">
        <MNavBar />
        <Box className="heroBanner">
          <img
            src="../images/contact-banner.webp"
            width="100%"
            className="bgimg"
          />
          <Box className="innerWrapper">
            <Grid container spacing={2} alignItems="center">
              <Grid
                xs={12}
                md={7}
                display="flex"
                gap="16px"
                flexDirection="column"
                sx={{ color: COLORS.white }}
                className="contentBox"
              >
                <h2>Connect with us for expert assistance and support!</h2>
              </Grid>
              <Grid xs={12} md={5}>
                <Box className="formBox">
                  <h3>Submit your details, we will reach out shortly.</h3>
                  <TextInputField
                    title="Enter your name"
                    value={contactformFields.name.value}
                    attrName={"name"}
                    value_update={updateMasterState}
                    warn_status={contactformFields.name.warn_status}
                    error_message={"Enter Name"}
                    validation_type="NAME"
                    placeholder="Eg: John Smith"
                  />
                  <TextInputField
                    title="Enter your email ID"
                    value={contactformFields.email.value}
                    attrName={"email"}
                    value_update={updateMasterState}
                    warn_status={contactformFields.email.warn_status}
                    error_message={
                      isEmpty(contactformFields.email.value)
                        ? "Enter E-mail"
                        : "Enter valid E-mail"
                    }
                    max_length={50}
                    placeholder="Eg: john.smith@email.com"
                  />
                  <TextInputField
                    title="Enter your mobile number"
                    value={contactformFields.contact.value}
                    attrName={"contact"}
                    value_update={updateMasterState}
                    warn_status={contactformFields.contact.warn_status}
                    error_message={
                      isEmpty(contactformFields.contact.value)
                        ? "Enter Contact"
                        : "Enter valid contact"
                    }
                    max_length={10}
                    validation_type="NUMBER"
                    placeholder="Eg: 7788778877"
                  />
                  <SearchSelectDropdown
                    title="Choose the subject"
                    options={lookingfroInsuranceData}
                    value={contactformFields.lookingForIns.value}
                    attrName={"lookingForIns"}
                    value_update={updateMasterState}
                    warn_status={contactformFields.lookingForIns.warn_status}
                    error_message={"Select Insurance"}
                  />
                  <CustomCheckbox
                    label={
                      <>
                        I agree to{" "}
                        <Link
                          style={{ color: COLORS.darkgold }}
                          onClick={() => {
                            navigate(COMMON_ROUTES.TERM_AND_CONDTION);
                          }}
                          target="_blank"
                        >
                          Terms and Conditions
                        </Link>
                      </>
                    }
                    attrName="termsandconditions"
                    value={true}
                    defaultChecked={false}
                    disabled={false}
                    variant="large"
                  />
                  <JDSButtons
                    text={"Submit"}
                    variant={"text"}
                    className={"primaryBtn large"}
                    onClick={() => {
                      validate_form();
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box
          sx={{ background: COLORS.lightMariGold20 }}
          padding={"8px 12px 24px"}
          className="contact_option_m"
          marginTop={"12px"}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid xs={12}>
              <Carousel
                responsive={responsive}
                autoPlay={true}
                swipeable={true}
                draggable={true}
                showDots={true}
                infinite={true}
                partialVisible={false}
                removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                // dotListClass={`custom-dot-list-style ${styles.customDotListStyle}`}
                containerClass={`custom-carousel-track customCarouselTrack`}
                customTransition="transform 0.5s ease-in-out"
              >
                {/* <Box
                sx={{
                  background: COLORS.white,
                  padding: "24px",
                  borderRadius: "24px",
                  height: "100%",
                }}
              >
                <img
                  src="./images/ic_contact_us.svg"
                  height="40px"
                  alt="icon"
                  width={"auto"}
                />
                <p
                  style={{
                    fontSize: "16px",
                    lineHeight: "20px",
                    fontWeight: "bold",
                    marginTop: "8px",
                    marginBottom: "8px",
                  }}
                >
                  Helpdesk
                </p>
                <h6 style={{ color: COLORS.lightgrey }}>090 99887 6654</h6>
              </Box> */}
                <Box
                  sx={{
                    background: COLORS.white,
                    padding: "24px",
                    borderRadius: "24px",
                    height: "100%",
                  }}
                >
                  <img
                    src="./images/ic_mail.svg"
                    height="40px"
                    alt="icon"
                    width={"auto"}
                  />
                  <p
                    style={{
                      fontSize: "16px",
                      lineHeight: "20px",
                      fontWeight: "bold",
                      marginTop: "8px",
                      marginBottom: "8px",
                    }}
                  >
                    Email
                  </p>
                  <Link
                    sx={{
                      color: COLORS.lightgrey,
                      fontSize: "16px",
                      fontWeight: "500",
                      wordWrap: "break-word",
                    }}
                    href="mailto:customer.care@jioinsure.in"
                  >
                    customer.care@jioinsure.in
                  </Link>
                </Box>
                <Box
                  sx={{
                    background: COLORS.white,
                    padding: "24px",
                    borderRadius: "24px",
                    height: "100%",
                  }}
                >
                  <img
                    src="./images/ic_mail.svg"
                    height="40px"
                    alt="icon"
                    width={"auto"}
                  />
                  <p
                    style={{
                      fontSize: "16px",
                      lineHeight: "20px",
                      fontWeight: "bold",
                      marginTop: "8px",
                      marginBottom: "8px",
                    }}
                  >
                    Careers
                  </p>
                  <Link
                    sx={{
                      color: COLORS.lightgrey,
                      fontSize: "16px",
                      fontWeight: "500",
                      wordWrap: "break-word",
                    }}
                    href="mailto:human.resource@jioinsure.in "
                  >
                    human.resource@jioinsure.in
                  </Link>
                </Box>
                <Box
                  sx={{
                    background: COLORS.white,
                    padding: "24px",
                    borderRadius: "24px",
                    height: "100%",
                  }}
                >
                  <img
                    src="./images/ic_location.svg"
                    height="40px"
                    alt="icon"
                    width={"auto"}
                  />
                  <h6
                    style={{
                      fontSize: "16px",
                      lineHeight: "20px",
                      fontWeight: "bold",
                      marginTop: "8px",
                      marginBottom: "8px",
                    }}
                  >
                    Office Address
                  </h6>
                  <p style={{ color: COLORS.lightgrey }}>
                    1st Floor B Wing, Building 25, Dhirubhai Ambani Knowledge
                    City, MIDC, Kopar Khairane, Navi Mumbai - 400710, India.
                  </p>
                </Box>
              </Carousel>
            </Grid>
          </Grid>
        </Box>

        {/* <Box
        display="flex"
        flexDirection="column"
        gap="32px"
        sx={{
          backgroundColor: COLORS.white,
          borderRadius: "24px",
          boxShadow: "0px 4px 16px rgba(0,0,0,0.08)",
          padding: "24px",
          margin: "0px 24px",
          position: "relative",
          top: "-44px",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap="12px"
          alignItems="center"
        >
          <img src="./images/ic_contact_us.svg" height="30px" alt="icon" />
          <h5>Helpdesk</h5>
          <h6 style={{ color: COLORS.lightgrey }}>090 99887 6654</h6>
          
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="12px"
          alignItems="center"
        >
          <img src="./images/ic_mail.svg" height="30px" alt="icon" />
          <h5>Email</h5>
          <Link
            sx={{
              color: COLORS.lightgrey,
              fontSize: "16px",
              fontWeight: "500",
            }}
            href="mailto:customer.care@jioinsure.in"
          >
            customer.care@jioinsure.in
          </Link>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="12px"
          alignItems="center"
        >
          <img src="./images/ic_mail.svg" height="30px" alt="icon" />
          <h5>Careers</h5>
          <Link
            sx={{
              color: COLORS.lightgrey,
              fontSize: "16px",
              fontWeight: "500",
            }}
            href="mailto:human.resource@jioinsure.in "
          >
            human.resource@jioinsure.in
          </Link>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="12px"
          alignItems="center"
        >
          <img src="./images/ic_location.svg" height="30px" alt="icon" />
          <h5>Office Address</h5>
          <h6 style={{ color: COLORS.lightgrey, textAlign: "center" }}>
            1st Floor B Wing, Building 25, Dhirubhai Ambani Knowledge City,
            MIDC, Kopar Khairane, Navi Mumbai - 400710, India.
          </h6>
        </Box>
      </Box>

      <Box
        sx={{
          backgroundColor: COLORS.lightMariGold30,
          padding: "24px",
          marginBottom: "50px",
          borderRadius: "48px",
        }}
      >
        <Box
          sx={{
            backgroundColor: COLORS.white,
            borderRadius: "24px",
            padding: "16px",
          }}
        >
          
          <Grid container spacing={2}>
            <Grid xs={12} marginBottom={"8px"}>
              <TextInputField
                title="Name"
                value={contactformFields.name.value}
                attrName={"name"}
                value_update={updateMasterState}
                warn_status={contactformFields.name.warn_status}
                error_message={"Enter Name"}
                validation_type="NAME"
              />
            </Grid>
            <Grid xs={12} marginBottom={"8px"}>
              <TextInputField
                title="Email"
                value={contactformFields.email.value}
                attrName={"email"}
                value_update={updateMasterState}
                warn_status={contactformFields.email.warn_status}
                error_message={
                  isEmpty(contactformFields.email.value)
                    ? "Enter email"
                    : "Enter valid email"
                }
                max_length={50}
              />
            </Grid>
            <Grid xs={12} marginBottom={"8px"}>
              <Box className="mobileNumber">
                <Box
                  sx={{
                    fontSize: "16px",
                    color: COLORS.lightgrey,
                    position: "relative",
                    padding: "21px 8px 12px 0px",
                  }}
                >
                  +91
                </Box>
                <TextInputField
                  title="Mobile Number"
                  value={contactformFields.contact.value}
                  attrName={"contact"}
                  value_update={updateMasterState}
                  warn_status={contactformFields.contact.warn_status}
                  error_message={
                    isEmpty(contactformFields.contact.value)
                      ? "Enter Contact"
                      : "Enter valid contact"
                  }
                  max_length={10}
                  validation_type="NUMBER"
                />
              </Box>
            </Grid>
            <Grid xs={12}>
              <CustomCheckbox
                label={
                  <span style={{ fontSize: "14px" }}>
                    I agree to{" "}
                    <Link
                      style={{ color: COLORS.darkgold }}
                      href="./pdfs/terms_of_use.pdf"
                      target="_blank"
                    >
                      Terms and Conditions
                    </Link>
                  </span>
                }
                attrName="termsandconditions"
                value={true}
                defaultChecked={true}
                disabled={true}
              />
            </Grid>
            <Grid xs={12} marginBottom={"16px"}>
              <SelectDropdown
                title="Looking for"
                data={lookingfroInsuranceData}
                value={contactformFields.lookingForIns.value}
                attrName={"lookingForIns"}
                value_update={updateMasterState}
                warn_status={contactformFields.lookingForIns.warn_status}
                error_message={"Select Insurance"}
              />
            </Grid>
            <Grid xs={12} className="mt-2">
              <JDSButtons
                text={"Submit"}
                variant={"text"}
                className={"primaryBtn large"}
                onClick={() => {
                  validate_form();
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box> */}

        <MFooter />
      </Box>
    </>
  );
}

export default MContact;
