import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Link, Tab, Tabs } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { toast } from "react-toastify";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import BlogCard from "../../../Component/Desktop/Common/BlogCard/BlogCard";
import TestimonialCard from "../../../Component/Desktop/Common/TestimonialCard/TestimonialCard";
import FAQ from "../../../Component/Global/FAQ/FAQ";
import GenderRadio from "../../../Component/Global/GenderRadio/GenderRadio";
import CustomCheckbox from "../../../Component/InputFields/CustomCheckbox/CustomCheckbox";
import {
  default as DatePicker,
  default as DatePickerInput,
} from "../../../Component/InputFields/DatePicker/DatePicker";
import JDSButtons from "../../../Component/InputFields/JDSButtons/JDSButtons";
import TextInputField from "../../../Component/InputFields/TextInputField/TextInputField";
import MContactusPopup from "../../../Component/Mobile/Common/MContactusPopup/MContactusPopup";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import CarFormContainer from "../../../Container/Car/Form/CarFormContainer";
import HealthFormContainer from "../../../Container/Health/Form/HealthFormContainer";
import NonTermFormContainer from "../../../Container/Non-term/Form/NonTermFormContainer";
import TWFormContainer from "../../../Container/TW/Form/TWFormContainer";
import TermFormContainer from "../../../Container/Term/Form/TermFormContainer";
import { COMMON_ROUTES } from "../../../Router/Path/CommonRoutes";
import { CarSlice } from "../../../Store/Slice_Reducer/Car/CarSlice";
import { HealthSlice } from "../../../Store/Slice_Reducer/Health/HealthSlice";
import { NonTermSlice } from "../../../Store/Slice_Reducer/NonTerm/NonTermSlice";
import { TermSlice } from "../../../Store/Slice_Reducer/Term/TermSlice";
import { TWSlice } from "../../../Store/Slice_Reducer/TwoWheeler/TWSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import HelmetComponent from "../../../SupportingFiles/HelmetComponent";
import {
  isEmpty,
  validateEmail,
  validateMobileNumber,
  validatePincode,
  validateRegno,
} from "../../../SupportingFiles/HelpingFunction";
import { COLORS } from "../../../SupportingFiles/colors";
import { pushDataLayer, sendEventToNativeApp } from "../../../utils/analytics";
import MCarPreFilledData from "../Car/MCarPrefilledDataPopup/MCarPrefilledDataPopup";
import MTWPreFilledData from "../TW/Form/MTWPrefilledDataPopup";
import "./MHome.scss";

function MHome({
  loader,
  openCarForm,
  openCarPrefilledData,
  openHealthForm,
  openNonTermForm,
  openTermForm,
  openTWForm,
  openTWPrefilledData,
  validateCarRollover,
  validateHealth,
  validateNonTerm,
  validateTerm,
  validateTWRollover,
  continuePrefilledCar,
  denyPrefilledCar,
  continuePrefilledTW,
  denyPrefilledTW,
  pageStatus,
  openContactusPopup,
  setOpenContactusPopup,
  setOpenCarPrefilledData,
  setOpenHealthForm,
  setOpenNonTermForm,
  setOpenTermForm,
  setOpenTWForm,
  setOpenTWPrefilledData,
  setOpenCarForm,
  setPageStatus,
  headerSliderArray,
  whySectionArray,
  responsive,
  partersLogos,
  testimonialsSlider,
  partnersSlider,
  gifaqs,
  carfaqs,
  bikefaqs,
  healthfaqs,
  termfaqs,
  blogCardsArray,
}: {
  loader: boolean;
  openCarForm: boolean;
  openCarPrefilledData: boolean;
  openHealthForm: boolean;
  openNonTermForm: boolean;
  openTermForm: boolean;
  openTWForm: boolean;
  openTWPrefilledData: boolean;
  validateCarRollover: Function;
  validateHealth: Function;
  validateNonTerm: Function;
  validateTerm: Function;
  validateTWRollover: Function;
  continuePrefilledCar: Function;
  denyPrefilledCar: Function;
  continuePrefilledTW: Function;
  denyPrefilledTW: Function;
  pageStatus: any;
  openContactusPopup: boolean;
  setOpenContactusPopup: Function;
  setOpenCarPrefilledData: Function;
  setOpenHealthForm: Function;
  setOpenNonTermForm: Function;
  setOpenTermForm: Function;
  setOpenTWForm: Function;
  setOpenTWPrefilledData: Function;
  setOpenCarForm: Function;
  setPageStatus: Function;
  headerSliderArray: any[];
  whySectionArray: any[];
  responsive: any;
  partersLogos: any;
  testimonialsSlider: any[];
  partnersSlider: any[];
  gifaqs: any[];
  carfaqs: any[];
  bikefaqs: any[];
  healthfaqs: any[];
  termfaqs: any[];
  blogCardsArray: any[];
}) {
  const dispatch = useAppDispatch();
  const { NonTerm, Car, TW, Health, Term } = useAppSelector((state) => state);
  const [activeformTabs, setActiveFormTabs] = React.useState("carForm");
  const navigate = useNavigate();

  console.log("testimonialsSlider", testimonialsSlider);
  useEffect(() => {
    if (openTWPrefilledData || openCarPrefilledData) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [openTWPrefilledData, openCarPrefilledData]);

  useEffect(() => {
    dispatch(TermSlice.actions.BULK_UPDATE(TermSlice.getInitialState()));
  }, [activeformTabs, window.location.pathname]);
  const [settings] = useState({
    className: "center",
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    // cssEase: "linear",
    arrows: false,
    dots: true,
    // fade: true,
  });

  const [faqtabs, setfaqtabs] = useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setfaqtabs(newValue);
  };

  const handleFormTabsChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setActiveFormTabs(newValue);
  };

  return (
    <>
      <HelmetComponent
        title="Get Car, Bike, Health & Life Insurance with JioInsure"
        description="Get your car, bike, health, or Life insurance online. Comprehensive coverage across India. Book now and get the right quotes! JioInsure - Jio General Insurance"
      />
      <Box className="mhomeWrapper">
        {openContactusPopup ? (
          <MContactusPopup
            open={openContactusPopup}
            setOpen={setOpenContactusPopup}
          />
        ) : null}
        <MNavBar />
        {openTWForm ? (
          <TWFormContainer
            pageStatus={pageStatus}
            setPageStatus={setPageStatus}
            open={openTWForm}
            setOpen={setOpenTWForm}
          />
        ) : null}

        <MTWPreFilledData
          open={openTWPrefilledData}
          setOpen={setOpenTWPrefilledData}
          continuePrefilledTW={continuePrefilledTW}
          denyPrefilledTW={denyPrefilledTW}
        />
        {openCarForm ? (
          <CarFormContainer
            open={openCarForm}
            setOpen={setOpenCarForm}
            pageStatus={pageStatus}
            setPageStatus={setPageStatus}
          />
        ) : null}

        <MCarPreFilledData
          open={openCarPrefilledData}
          setOpen={setOpenCarPrefilledData}
          continuePrefilledCar={continuePrefilledCar}
          denyPrefilledCar={denyPrefilledCar}
        />
        <TermFormContainer open={openTermForm} setOpen={setOpenTermForm} />

        {/* Hero Banner */}
        <Box sx={{ marginBottom: "64px" }}>
          <Slider {...settings}>
            {headerSliderArray?.map((data, index) => (
              <Box
                sx={{
                  width: "100%",
                  position: "relative",
                  marginBottom: "12px",
                }}
              >
                <img
                  alt=""
                  src={data.slider_img}
                  width="100%"
                  style={{
                    minHeight: "440px",
                    maxHeight: "440px",
                    // maxHeight: "62vh",
                    // height: "62vh",
                    objectPosition: "center top",
                    objectFit: "cover",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "36px",
                    width: "100%",
                    color: "#fff",
                  }}
                >
                  <Grid container columnSpacing={6}>
                    <Grid
                      xs={12}
                      display="flex"
                      gap="16px"
                      flexDirection="column"
                    >
                      <h6>{data.INS_Type_tag}</h6>
                      <h3
                        style={{
                          fontSize: "24px",
                          lineHeight: "28px",
                          fontWeight: 900,
                        }}
                      >
                        {data.tagline}
                      </h3>

                      <JDSButtons
                        text="Know more"
                        variant={"text"}
                        className={"primaryBtn mt-4"}
                        onClick={() => {
                          pushDataLayer(
                            "jioinsure_home_promo",
                            data.INS_Type_tag,
                            `click`,
                            "Insure website"
                          );

                          switch (data.INS_Type_tag) {
                            case "Two-Wheeler Insurance":
                              navigate(COMMON_ROUTES.PRODUCT_TW);
                              break;

                            case "Health Insurance":
                              navigate(COMMON_ROUTES.PRODUCT_HEALTH);
                              break;

                            case "Car Insurance":
                              navigate(COMMON_ROUTES.PRODUCT_CAR);
                              break;

                            case "Life Insurance":
                              navigate(COMMON_ROUTES.PRODUCT_TERM);
                              break;

                            default:
                              break;
                          }
                        }}
                        fullWidth={false}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            ))}
          </Slider>
        </Box>
        {/* Hero Banner End */}

        {/* <Box className="headerCarousel" marginBottom={"72px"}>
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12}>
            <Slider {...settings}>
              {headerSliderArray?.map((data, index) => (
                <div className="boxOuter">
                  <div className="cb_box">
                    <Box
                      className="cb_Boxinner"
                      style={{ background: data.sliderBg }}
                    >
                      <Box className="person_box">
                        <img src={data.slider_img} alt="" />
                      </Box>
                      <Box className="advisor_link_box">
                        <h6
                          style={{
                            fontSize: "10px",
                            fontWeight: 700,
                            lineHeight: "16px",
                            color: "#906729",
                            textTransform: "uppercase",
                            marginBottom: "4px",
                          }}
                        >
                          {data.INS_Type_tag}
                        </h6>
                        <p
                          className="mb-2"
                          style={{
                            color: "#654924",
                            fontWeight: "900",
                            fontSize: "16px",
                            lineHeight: "20px",
                          }}
                        >
                          {data.tagline}
                        </p>
                        <Button
                          className="greyBtn"
                          disableRipple
                          onClick={() => {
                            if (data.INS_Type_tag === "Life Insurance") {
                              navigate(COMMON_ROUTES.PRODUCT_TERM);
                            } else if (
                              data.INS_Type_tag === "Health Insurance"
                            ) {
                              navigate(COMMON_ROUTES.PRODUCT_HEALTH);
                            } else if (
                              data.INS_Type_tag === "Secure Automobiles"
                            ) {
                              navigate(COMMON_ROUTES.PRODUCT_CAR);
                            } else if (
                              data.INS_Type_tag === "Two wheeler Insurance"
                            ) {
                              navigate(COMMON_ROUTES.PRODUCT_TW);
                            }
                          }}
                        >
                          Learn more
                        </Button>
                      </Box>
                    </Box>
                  </div>
                </div>
              ))}
            </Slider>
          </Grid>
        </Grid>
      </Box> */}

        {/* new sec tabs sec---------------- */}
        <Box className="formSection" marginBottom={"48px"}>
          <Box padding={"0 12px"} marginBottom={"24px"}>
            <Grid container className="row" columnSpacing={3}>
              <Grid xs={12}>
                <h2
                  style={{
                    fontSize: "24px",
                    lineHeight: "28px",
                    marginBottom: "12px",
                  }}
                >
                  Smart insurances for a smarter generation
                </h2>
              </Grid>
              <Grid xs={12}>
                <h6
                  style={{
                    color: COLORS.lightgrey,
                    marginBottom: "12px",
                  }}
                >
                  I'm looking to insure
                </h6>
              </Grid>
              <Grid xs={12} className="tabsBox" textAlign={"center"}>
                <Box
                  style={{
                    background: COLORS.lightMariGold20,
                    padding: "16px",
                    borderRadius: "24px",
                  }}
                >
                  <Box
                    marginBottom={"16px"}
                    sx={{
                      maxWidth: "800px",
                      borderRadius: "100px",
                      backgroundColor: COLORS.white,
                      padding: "4px",
                      display: "flex",
                      justifyContent: "center",
                      ".MuiTab-root": {
                        flex: "none!important",
                        width: "auto!important",
                        padding: "8px 16px!important",
                      },
                    }}
                  >
                    <Tabs
                      value={activeformTabs}
                      onChange={handleFormTabsChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                      allowScrollButtonsMobile
                    >
                      <Tab label={<>Car</>} value={"carForm"} />
                      <Tab label={<>Two-Wheeler</>} value={"bikeForm"} />
                      <Tab label={<>Health</>} value={"healthForm"} />
                      <Tab label={<>Term</>} value={"termForm"} />
                      {process.env.REACT_APP_ENV === "DEV" ? (
                        <Tab label={<>Investment</>} value={"investmentForm"} />
                      ) : null}
                    </Tabs>
                  </Box>

                  <Box>
                    <TabContext value={activeformTabs}>
                      <TabPanel value={"carForm"}>
                        {" "}
                        <>
                          <h6
                            style={{
                              marginBottom: "12px",
                              fontWeight: 900,
                              lineHeight: "20px",
                            }}
                          >
                            Buy car insurance and save up to 70%*
                          </h6>
                          <Box
                            maxWidth="384px"
                            margin="0px auto"
                            display="flex"
                            flexDirection="column"
                            gap="16px"
                          >
                            <Box>
                              <TextInputField
                                title="Enter car registration number"
                                value={Car.ADD_FORM.reg_no.value}
                                attrName={"reg_no"}
                                value_update={(a: any, v: any) => {
                                  dispatch(
                                    CarSlice.actions.UPDATE_FORM_DATA([
                                      {
                                        key: "reg_no",
                                        value: {
                                          value: v,
                                          warning: !validateRegno(v),
                                        },
                                      },
                                    ])
                                  );
                                }}
                                warn_status={Car.ADD_FORM.reg_no.warning}
                                error_message={
                                  !isEmpty(Car.ADD_FORM.reg_no.value)
                                    ? "Enter Valid Vehicle number"
                                    : "Enter Vehicle number"
                                }
                                placeholder={"Eg: MH00XX0000"}
                                validation_type="ALPHANUMERIC"
                                max_length={11}
                              />
                            </Box>
                            <Box className="mobileNumber">
                              <Box
                                position="absolute"
                                left="12px"
                                bottom="13px"
                                zIndex="2"
                                fontSize="18px"
                                fontWeight="bold"
                                sx={{ color: COLORS.lightgrey }}
                              >
                                +91
                              </Box>
                              <TextInputField
                                title="Enter mobile number"
                                validation_type="NUMBER"
                                value={Car.ADD_FORM.mobile.value}
                                placeholder="XXXXXXXXXX"
                                attrName={"mobile"}
                                value_update={(a: any, v: any) => {
                                  dispatch(
                                    CarSlice.actions.UPDATE_FORM_DATA([
                                      {
                                        key: "mobile",
                                        value: {
                                          value: v,
                                          warning: !validateMobileNumber(v),
                                        },
                                      },
                                    ])
                                  );
                                }}
                                warn_status={Car.ADD_FORM.mobile.warning}
                                error_message={
                                  isEmpty(Car.ADD_FORM.mobile.value)
                                    ? "Enter Mobile Number"
                                    : "Enter valid mobile number"
                                }
                                max_length={10}
                              />
                            </Box>

                            <Box>
                              <TextInputField
                                title="Enter your email"
                                placeholder="Eg: john.smith@email.com"
                                value={Car.ADD_FORM.email?.value}
                                attrName={"email"}
                                value_update={(a: any, v: any) => {
                                  dispatch(
                                    CarSlice.actions.UPDATE_FORM_DATA([
                                      {
                                        key: "email",
                                        value: {
                                          value: v,
                                          warning: !validateEmail(v),
                                        },
                                      },
                                    ])
                                  );
                                }}
                                warn_status={Car.ADD_FORM.email?.warning}
                                error_message={
                                  isEmpty(Car.ADD_FORM.email?.value)
                                    ? "Enter E-mail"
                                    : "Enter valid email"
                                }
                                max_length={50}
                              />
                            </Box>
                            <CustomCheckbox
                              label={
                                <>
                                  I agree to{" "}
                                  <Link
                                    style={{
                                      color: COLORS.primary,
                                      fontWeight: "bold",
                                    }}
                                    onClick={() => {
                                      navigate(COMMON_ROUTES.TERM_AND_CONDTION);
                                    }}
                                  >
                                    Terms & Conditions
                                  </Link>
                                  ,{" "}
                                  <Link
                                    style={{
                                      color: COLORS.primary,
                                      fontWeight: "bold",
                                    }}
                                    onClick={() => {
                                      navigate(COMMON_ROUTES.PRIVACY_POLICY);
                                    }}
                                  >
                                    Privacy Policy
                                  </Link>
                                  {` and `}
                                  <Link
                                    style={{
                                      color: COLORS.primary,
                                      fontWeight: "bold",
                                    }}
                                    onClick={() => {
                                      navigate(COMMON_ROUTES.DISCLAIMER);
                                    }}
                                  >
                                    Disclaimer
                                  </Link>
                                </>
                              }
                              attrName="termsandconditions"
                              variant="large"
                              value={Car?.ADD_FORM?.term_cond}
                              defaultChecked={Car?.ADD_FORM?.term_cond}
                              value_update={(a: any, v: boolean) => {
                                dispatch(
                                  CarSlice.actions.ADD_FORM_DATA({
                                    ...Car.ADD_FORM,
                                    term_cond: v,
                                  })
                                );
                              }}
                            />

                            <Grid container spacing={0}>
                              <Grid xs={12}>
                                <JDSButtons
                                  className="secondaryBtn large mb-3"
                                  text="Bought a new car?"
                                  variant="text"
                                  onClick={() => {
                                    dispatch(
                                      CarSlice.actions.UPDATE_FORM_DATA([
                                        { key: "business_type", value: "New" },
                                        {
                                          key: "reg_no",
                                          value: { value: "", warning: false },
                                        },
                                        {
                                          key: "mobile",
                                          value: { value: "", warning: false },
                                        },
                                      ])
                                    );
                                    if (Car.ADD_FORM.term_cond) {
                                      pushDataLayer(
                                        "jioinsure_home_cards",
                                        "car insurance",
                                        "Bought a new car?",
                                        "Insure website"
                                      );
                                      const eventProps = {
                                        action: "car insurance",
                                        click: "Bought a new car?",
                                        EntrySource: "Insure website",
                                      };
                                      sendEventToNativeApp(
                                        "jioinsure_home_cards",
                                        eventProps
                                      );
                                      setPageStatus(1);
                                      setOpenCarForm(true);
                                    } else {
                                      toast.error(
                                        "Please accept terms and conditions"
                                      );
                                    }
                                  }}
                                />
                              </Grid>
                              <Grid xs={12}>
                                <JDSButtons
                                  onClick={() => {
                                    if (Car?.ADD_FORM?.term_cond) {
                                      validateCarRollover();
                                    } else {
                                      toast.error(
                                        "Please accept terms and conditions"
                                      );
                                    }
                                  }}
                                  className="primaryBtn large "
                                  text="Get free quotes"
                                  variant="text"
                                  loader={loader}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </>
                      </TabPanel>

                      <TabPanel value={"bikeForm"}>
                        <>
                          <h6
                            style={{
                              marginBottom: "16px",
                              fontWeight: 900,
                              lineHeight: "20px",
                            }}
                          >
                            Buy two-wheeler insurance and save up to 80%*
                          </h6>

                          <Box
                            maxWidth="384px"
                            margin="0px auto"
                            display="flex"
                            flexDirection="column"
                            gap="16px"
                          >
                            <Box>
                              <TextInputField
                                title="Enter bike registration number"
                                class_name=""
                                value={TW.ADD_FORM.reg_no.value}
                                attrName={"reg_no"}
                                value_update={(a: any, v: any) => {
                                  dispatch(
                                    TWSlice.actions.UPDATE_FORM_DATA([
                                      {
                                        key: "reg_no",
                                        value: {
                                          value: v,
                                          warning: !validateRegno(v),
                                        },
                                      },
                                    ])
                                  );
                                }}
                                warn_status={TW.ADD_FORM.reg_no.warning}
                                error_message={
                                  !isEmpty(TW.ADD_FORM.reg_no.value)
                                    ? "Enter Valid Vehicle number"
                                    : "Enter Vehicle number"
                                }
                                placeholder={"Eg: MH00XX0000"}
                                validation_type="ALPHANUMERIC"
                                max_length={11}
                              />
                            </Box>
                            <Box className="mobileNumber">
                              <Box
                                position="absolute"
                                left="12px"
                                bottom="13px"
                                zIndex="2"
                                fontSize="18px"
                                fontWeight="bold"
                                sx={{ color: COLORS.lightgrey }}
                              >
                                +91
                              </Box>
                              <TextInputField
                                title="Enter mobile number"
                                validation_type="NUMBER"
                                placeholder="XXXXXXXXXX"
                                value={TW.ADD_FORM.mobile.value}
                                attrName={"mobile"}
                                value_update={(a: any, v: any) => {
                                  dispatch(
                                    TWSlice.actions.UPDATE_FORM_DATA([
                                      {
                                        key: "mobile",
                                        value: {
                                          value: v,
                                          warning: !validateMobileNumber(v),
                                        },
                                      },
                                    ])
                                  );
                                }}
                                warn_status={TW.ADD_FORM.mobile.warning}
                                error_message={
                                  isEmpty(TW.ADD_FORM.mobile.value)
                                    ? "Enter Mobile Number"
                                    : "Enter valid mobile number"
                                }
                                max_length={10}
                              />
                            </Box>
                            <Box>
                              <TextInputField
                                title="Enter your email"
                                placeholder="Eg: john.smith@email.com"
                                value={TW.ADD_FORM.email?.value}
                                attrName={"email"}
                                value_update={(a: any, v: any) => {
                                  dispatch(
                                    TWSlice.actions.UPDATE_FORM_DATA([
                                      {
                                        key: "email",
                                        value: {
                                          value: v,
                                          warning: !validateEmail(v),
                                        },
                                      },
                                    ])
                                  );
                                }}
                                warn_status={TW.ADD_FORM.email?.warning}
                                error_message={
                                  isEmpty(TW.ADD_FORM.email?.value)
                                    ? "Enter E-mail"
                                    : "Enter valid E-mail"
                                }
                                max_length={50}
                              />
                            </Box>
                            <CustomCheckbox
                              label={
                                <>
                                  I agree to{" "}
                                  <Link
                                    style={{
                                      color: COLORS.primary,
                                      fontWeight: "bold",
                                    }}
                                    onClick={() => {
                                      navigate(COMMON_ROUTES.TERM_AND_CONDTION);
                                    }}
                                  >
                                    Terms & Conditions
                                  </Link>
                                  ,{" "}
                                  <Link
                                    style={{
                                      color: COLORS.primary,
                                      fontWeight: "bold",
                                    }}
                                    onClick={() => {
                                      navigate(COMMON_ROUTES.PRIVACY_POLICY);
                                    }}
                                  >
                                    Privacy Policy
                                  </Link>
                                  {` and `}
                                  <Link
                                    style={{
                                      color: COLORS.primary,
                                      fontWeight: "bold",
                                    }}
                                    onClick={() => {
                                      navigate(COMMON_ROUTES.DISCLAIMER);
                                    }}
                                  >
                                    Disclaimer
                                  </Link>
                                </>
                              }
                              attrName="termsandconditions"
                              variant="large"
                              value={TW?.ADD_FORM?.term_cond}
                              defaultChecked={TW?.ADD_FORM?.term_cond}
                              value_update={(a: any, v: boolean) => {
                                dispatch(
                                  TWSlice.actions.ADD_FORM_DATA({
                                    ...TW.ADD_FORM,
                                    term_cond: v,
                                  })
                                );
                              }}
                            />
                            <Grid container spacing={0}>
                              <Grid xs={12}>
                                <JDSButtons
                                  className="secondaryBtn large mb-3"
                                  text="Bought a new two-wheeler?"
                                  variant="text"
                                  onClick={() => {
                                    dispatch(
                                      TWSlice.actions.BULK_UPDATE({
                                        ...TW,
                                        ADD_FORM: {
                                          ...TWSlice.getInitialState().ADD_FORM,
                                          business_type: "New",
                                        },
                                      })
                                    );
                                    if (TW.ADD_FORM.term_cond) {
                                      setPageStatus(1);
                                      setOpenTWForm(true);
                                      pushDataLayer(
                                        "jioinsure_home_cards",
                                        "two wheeler insurance",
                                        "Bought a new two-wheeler?",
                                        "Insure website"
                                      );
                                      const eventProps = {
                                        action: "two wheeler insurance",
                                        click: "Bought a new two-wheeler?",
                                        EntrySource: "Insure website",
                                      };
                                      sendEventToNativeApp(
                                        "jioinsure_home_cards",
                                        eventProps
                                      );
                                    } else {
                                      toast.error(
                                        "Please accept terms and conditions"
                                      );
                                    }
                                  }}
                                />
                              </Grid>
                              <Grid xs={12}>
                                <JDSButtons
                                  onClick={() => {
                                    if (TW?.ADD_FORM?.term_cond) {
                                      validateTWRollover();
                                    } else {
                                      toast.error(
                                        "Please accept terms and conditions"
                                      );
                                    }
                                  }}
                                  className="primaryBtn large"
                                  text="Get free quotes"
                                  variant="text"
                                  loader={loader}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </>
                      </TabPanel>

                      <TabPanel value={"healthForm"}>
                        <>
                          <h6
                            style={{
                              marginBottom: "16px",
                              fontWeight: 900,
                              lineHeight: "20px",
                            }}
                          >
                            Healthy choices start here: compare health insurance
                            plans
                          </h6>
                          <Box
                            maxWidth="384px"
                            margin="0px auto"
                            display="flex"
                            flexDirection="column"
                            gap="16px"
                          >
                            <Box>
                              <p
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                  marginBottom: "8px",
                                }}
                              >
                                Select your gender
                              </p>
                              <GenderRadio
                                value={Health.ADD_FORM.gender.value}
                                attrName={"gender"}
                                defaultValue={Health.ADD_FORM.gender.value}
                                value_update={(a: any, v: any) => {
                                  dispatch(
                                    HealthSlice.actions.setAddFormData({
                                      ...Health.ADD_FORM,
                                      gender: {
                                        value: v,
                                        warning: isEmpty(v),
                                      },
                                    })
                                  );
                                }}
                                warn_status={Health.ADD_FORM.gender.warning}
                              />
                            </Box>
                            <Box className="mobileNumber">
                              <Box
                                position="absolute"
                                left="12px"
                                bottom="13px"
                                zIndex="2"
                                fontSize="18px"
                                fontWeight="bold"
                                sx={{ color: COLORS.lightgrey }}
                              >
                                +91
                              </Box>
                              <TextInputField
                                title="Enter mobile number"
                                placeholder="XXXXXXXXXX"
                                value={Health.ADD_FORM.mobile.value}
                                attrName={"mobile"}
                                value_update={(a: any, v: any) => {
                                  dispatch(
                                    HealthSlice.actions.setAddFormData({
                                      ...Health.ADD_FORM,
                                      mobile: {
                                        value: v,
                                        warning: !validateMobileNumber(v),
                                      },
                                    })
                                  );
                                }}
                                validation_type="NUMBER"
                                max_length={10}
                                warn_status={Health.ADD_FORM.mobile.warning}
                                error_message={
                                  isEmpty(Health.ADD_FORM.mobile.value)
                                    ? "Enter Mobile Number"
                                    : "Enter valid mobile number"
                                }
                              />
                            </Box>
                            <Box>
                              <TextInputField
                                title="Enter your email"
                                placeholder="Eg: john.smith@email.com"
                                value={Health.ADD_FORM.email?.value}
                                attrName={"email"}
                                value_update={(a: any, v: any) => {
                                  dispatch(
                                    HealthSlice.actions.setAddFormData({
                                      ...Health.ADD_FORM,
                                      email: {
                                        value: v,
                                        warning: isEmpty(v),
                                      },
                                    })
                                  );
                                }}
                                warn_status={Health.ADD_FORM.email?.warning}
                                error_message={
                                  isEmpty(Health.ADD_FORM.email?.value)
                                    ? "Enter E-mail"
                                    : "Enter valid E-mail"
                                }
                                max_length={50}
                              />
                            </Box>
                            <TextInputField
                              title="Enter your pincode"
                              placeholder="XXXXXX"
                              value={Health.ADD_FORM.pincode.value}
                              attrName={"pincode"}
                              validation_type="NUMBER"
                              value_update={(a: any, v: any) => {
                                dispatch(
                                  HealthSlice.actions.setAddFormData({
                                    ...Health.ADD_FORM,
                                    pincode: {
                                      value: v,
                                      warning: !validatePincode(v),
                                    },
                                  })
                                );
                              }}
                              max_length={6}
                              warn_status={Health.ADD_FORM.pincode.warning}
                              error_message={
                                isEmpty(Health.ADD_FORM.pincode.value)
                                  ? "Enter Pincode"
                                  : "Enter valid pincode"
                              }
                            />

                            <CustomCheckbox
                              label={
                                <>
                                  I agree to{" "}
                                  <Link
                                    style={{
                                      color: COLORS.primary,
                                      fontWeight: "bold",
                                    }}
                                    onClick={() => {
                                      navigate(COMMON_ROUTES.TERM_AND_CONDTION);
                                    }}
                                  >
                                    Terms & Conditions
                                  </Link>
                                  ,{" "}
                                  <Link
                                    style={{
                                      color: COLORS.primary,
                                      fontWeight: "bold",
                                    }}
                                    onClick={() => {
                                      navigate(COMMON_ROUTES.PRIVACY_POLICY);
                                    }}
                                  >
                                    Privacy Policy
                                  </Link>
                                  {` and `}
                                  <Link
                                    style={{
                                      color: COLORS.primary,
                                      fontWeight: "bold",
                                    }}
                                    onClick={() => {
                                      navigate(COMMON_ROUTES.DISCLAIMER);
                                    }}
                                  >
                                    Disclaimer
                                  </Link>
                                </>
                              }
                              attrName="termsandconditions"
                              variant="large"
                              value={Health?.ADD_FORM?.term_cond?.value}
                              defaultChecked={
                                Health?.ADD_FORM?.term_cond?.value
                              }
                              value_update={(a: any, v: boolean) => {
                                dispatch(
                                  HealthSlice.actions.setAddFormData({
                                    ...Health.ADD_FORM,
                                    term_cond: {
                                      value: v,
                                      warning: isEmpty(v),
                                    },
                                  })
                                );
                              }}
                            />
                            <JDSButtons
                              onClick={() => {
                                if (Health?.ADD_FORM?.term_cond?.value) {
                                  validateHealth();
                                } else {
                                  toast.error(
                                    "Please accept terms and conditions"
                                  );
                                }
                              }}
                              className="primaryBtn large"
                              text="Get Free Quotes"
                              variant="text"
                              // loader="true"
                            />
                          </Box>
                          {openHealthForm ? (
                            <HealthFormContainer
                              loader={loader}
                              open={openHealthForm}
                              setOpen={setOpenHealthForm}
                            />
                          ) : null}
                        </>
                      </TabPanel>
                      <TabPanel value={"termForm"}>
                        {" "}
                        <>
                          <Box>
                            <h6
                              style={{
                                marginBottom: "16px",
                                fontWeight: 900,
                                lineHeight: "20px",
                              }}
                            >
                              Buy term insurance with up to 20%* discount
                            </h6>
                            <Box
                              maxWidth="384px"
                              margin="0px auto"
                              display="flex"
                              flexDirection="column"
                              gap="16px"
                            >
                              <Box>
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                    marginBottom: "8px",
                                  }}
                                >
                                  Select your gender
                                </p>
                                <GenderRadio
                                  value={Term.ADD_FORM.gender.value}
                                  value_update={(a: string, v: string) => {
                                    dispatch(
                                      TermSlice.actions.UPDATE_ADD_FORM_DATA({
                                        ...Term.ADD_FORM,
                                        gender: { value: v, warning: false },
                                      })
                                    );
                                  }}
                                />
                              </Box>
                              <Box className="mobileNumber">
                                <Box
                                  position="absolute"
                                  left="12px"
                                  bottom="13px"
                                  zIndex="2"
                                  fontSize="18px"
                                  fontWeight="bold"
                                  sx={{ color: COLORS.lightgrey }}
                                >
                                  +91
                                </Box>
                                <TextInputField
                                  title="Enter mobile number"
                                  placeholder="XXXXXXXXXX"
                                  validation_type="NUMBER"
                                  value={Term.ADD_FORM.mobile.value}
                                  attrName={"mobile"}
                                  value_update={(a: any, v: any) => {
                                    dispatch(
                                      TermSlice.actions.UPDATE_ADD_FORM_DATA({
                                        ...Term.ADD_FORM,
                                        mobile: {
                                          value: v,
                                          warning: !validateMobileNumber(v),
                                        },
                                      })
                                    );
                                  }}
                                  warn_status={Term.ADD_FORM.mobile.warning}
                                  error_message={
                                    isEmpty(Term.ADD_FORM.mobile.value)
                                      ? "Enter Mobile Number"
                                      : "Enter valid mobile number"
                                  }
                                  max_length={10}
                                />
                              </Box>
                              <Box>
                                <TextInputField
                                  title="Enter your email"
                                  placeholder="Eg: john.smith@email.com"
                                  value={Term.ADD_FORM.email?.value}
                                  attrName={"email"}
                                  value_update={(a: any, v: any) => {
                                    dispatch(
                                      TermSlice.actions.UPDATE_ADD_FORM_DATA({
                                        ...Term.ADD_FORM,
                                        email: {
                                          value: v,
                                          warning: !validateEmail(v),
                                        },
                                      })
                                    );
                                  }}
                                  warn_status={Term.ADD_FORM.email?.warning}
                                  error_message={
                                    isEmpty(Term.ADD_FORM.email?.value)
                                      ? "Enter E-mail"
                                      : "Enter valid E-mail"
                                  }
                                  max_length={50}
                                />
                              </Box>
                              <DatePicker
                                title={"Date of Birth"}
                                value={Term.ADD_FORM.age.value}
                                attrName={"age"}
                                onChange={(a: any, v: any) => {
                                  dispatch(
                                    TermSlice.actions.UPDATE_ADD_FORM_DATA({
                                      ...Term.ADD_FORM,
                                      age: { value: v, warning: false },
                                    })
                                  );
                                }}
                                warn_status={Term.ADD_FORM.age.warning}
                                error_message="Select DOB"
                                min_date={65}
                                max_date={18}
                                default_date={new Date("1990-01-01")}
                              />
                              <CustomCheckbox
                                label={
                                  <>
                                    I agree to{" "}
                                    <Link
                                      style={{
                                        color: COLORS.primary,
                                        fontWeight: "bold",
                                      }}
                                      onClick={() => {
                                        navigate(
                                          COMMON_ROUTES.TERM_AND_CONDTION
                                        );
                                      }}
                                    >
                                      Terms & Conditions
                                    </Link>
                                    ,{" "}
                                    <Link
                                      style={{
                                        color: COLORS.primary,
                                        fontWeight: "bold",
                                      }}
                                      onClick={() => {
                                        navigate(COMMON_ROUTES.PRIVACY_POLICY);
                                      }}
                                    >
                                      Privacy Policy
                                    </Link>
                                    {` and `}
                                    <Link
                                      style={{
                                        color: COLORS.primary,
                                        fontWeight: "bold",
                                      }}
                                      onClick={() => {
                                        navigate(COMMON_ROUTES.DISCLAIMER);
                                      }}
                                    >
                                      Disclaimer
                                    </Link>
                                  </>
                                }
                                attrName="termsandconditions"
                                value={true}
                                defaultChecked={true}
                                variant="large"
                              />
                              <JDSButtons
                                className="primaryBtn large"
                                text="Get Free Quotes"
                                variant="text"
                                onClick={() => {
                                  validateTerm();
                                }}
                              />
                            </Box>
                          </Box>
                        </>
                      </TabPanel>
                      <TabPanel value={"investmentForm"}>
                        {" "}
                        <>
                          <Box>
                            <h6
                              style={{
                                marginBottom: "16px",
                                fontWeight: 900,
                                lineHeight: "20px",
                              }}
                            >
                              Buy an investment plan
                            </h6>
                            <Box
                              maxWidth="384px"
                              margin="0px auto"
                              display="flex"
                              flexDirection="column"
                              gap="16px"
                            >
                              <Box>
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                    marginBottom: "8px",
                                  }}
                                >
                                  Select your gender
                                </p>
                                <GenderRadio
                                  variant="small"
                                  value={NonTerm.ADD_FORM.gender.value}
                                  value_update={(a: string, v: string) => {
                                    dispatch(
                                      NonTermSlice.actions.NON_TERM_UPDATE_ADD_FORM_DATA(
                                        {
                                          ...NonTerm.ADD_FORM,
                                          gender: { value: v, warning: false },
                                        }
                                      )
                                    );
                                  }}
                                />
                              </Box>

                              <Box className="mobileNumber">
                                <Box
                                  position="absolute"
                                  left="12px"
                                  bottom="13px"
                                  zIndex="2"
                                  fontSize="18px"
                                  fontWeight="bold"
                                  sx={{ color: COLORS.lightgrey }}
                                >
                                  +91
                                </Box>

                                <TextInputField
                                  title="Enter mobile number"
                                  placeholder="XXXXXXXXXX"
                                  validation_type="NUMBER"
                                  value={NonTerm.ADD_FORM.mobile.value}
                                  attrName={"mobile"}
                                  value_update={(a: any, v: any) => {
                                    dispatch(
                                      NonTermSlice.actions.NON_TERM_UPDATE_ADD_FORM_DATA(
                                        {
                                          ...NonTerm.ADD_FORM,
                                          mobile: {
                                            value: v,
                                            warning: !validateMobileNumber(v),
                                          },
                                        }
                                      )
                                    );
                                  }}
                                  warn_status={NonTerm.ADD_FORM.mobile.warning}
                                  error_message={
                                    isEmpty(NonTerm.ADD_FORM.mobile.value)
                                      ? "Enter Mobile Number"
                                      : "Enter Valid Mobile Number"
                                  }
                                  max_length={10}
                                />
                              </Box>
                              <Box>
                                <TextInputField
                                  title="Enter your email"
                                  placeholder="Eg: abc@abc.com"
                                  value={NonTerm.ADD_FORM.email?.value}
                                  attrName={"email"}
                                  value_update={(a: any, v: any) => {
                                    dispatch(
                                      NonTermSlice.actions.NON_TERM_UPDATE_ADD_FORM_DATA(
                                        {
                                          ...NonTerm.ADD_FORM,
                                          email: {
                                            value: v,
                                            warning: !validateEmail(v),
                                          },
                                        }
                                      )
                                    );
                                  }}
                                  warn_status={NonTerm.ADD_FORM.email?.warning}
                                  error_message={
                                    isEmpty(NonTerm.ADD_FORM.email?.value)
                                      ? "Enter E-mail"
                                      : "Enter valid E-mail"
                                  }
                                  max_length={50}
                                />
                              </Box>
                              <DatePickerInput
                                title={"Date of Birth"}
                                value={NonTerm.ADD_FORM.age.value}
                                attrName={"age"}
                                onChange={(a: any, v: any) => {
                                  dispatch(
                                    NonTermSlice.actions.NON_TERM_UPDATE_ADD_FORM_DATA(
                                      {
                                        ...NonTerm.ADD_FORM,
                                        age: { value: v, warning: false },
                                      }
                                    )
                                  );
                                }}
                                warn_status={NonTerm.ADD_FORM.age.warning}
                                error_message="Select DOB"
                                min_date={65}
                                max_date={18}
                                default_date={new Date("1990-01-01")}
                              />
                              <CustomCheckbox
                                label={
                                  <>
                                    I agree to{" "}
                                    <Link
                                      style={{
                                        color: COLORS.primary,
                                        fontWeight: "bold",
                                      }}
                                      onClick={() => {
                                        navigate(
                                          COMMON_ROUTES.TERM_AND_CONDTION
                                        );
                                      }}
                                    >
                                      Terms & Conditions
                                    </Link>
                                    ,{" "}
                                    <Link
                                      style={{
                                        color: COLORS.primary,
                                        fontWeight: "bold",
                                      }}
                                      onClick={() => {
                                        navigate(COMMON_ROUTES.PRIVACY_POLICY);
                                      }}
                                    >
                                      Privacy Policy
                                    </Link>
                                    {` and `}
                                    <Link
                                      style={{
                                        color: COLORS.primary,
                                        fontWeight: "bold",
                                      }}
                                      onClick={() => {
                                        navigate(COMMON_ROUTES.DISCLAIMER);
                                      }}
                                    >
                                      Disclaimer
                                    </Link>
                                  </>
                                }
                                attrName="termsandconditions"
                                value={true}
                                defaultChecked={true}
                                variant="large"
                                disabled={true}
                              />
                              <JDSButtons
                                onClick={() => validateNonTerm()}
                                className="primaryBtn large"
                                text="Get Free Quotes"
                                variant="text"
                                // loader="true"
                              />
                              <NonTermFormContainer
                                open={openNonTermForm}
                                setOpen={setOpenNonTermForm}
                              />
                            </Box>
                          </Box>
                        </>
                      </TabPanel>
                    </TabContext>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* new sec tabs sec wnds */}

        <Box marginBottom={"48px"} className="why_jio_section_m">
          <Grid container className="row" columnSpacing={6}>
            <Grid xs={12} marginBottom={"24px"}>
              <h6
                className="mb-3"
                style={{
                  color: COLORS.lightgrey,
                  fontWeight: 700,
                }}
              >
                The Jio Advantage
              </h6>
              <h3
                style={{
                  fontSize: "24px",
                  lineHeight: "28px",
                  fontWeight: 900,
                }}
                className="mb-4"
              >
                Benefits of Jio Insurance
              </h3>
              <p
                style={{
                  color: COLORS.lightgrey,
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: 500,
                }}
              >
                At Jio Insurance Broking, we understand that finding the right
                insurance can be a complex and time-consuming process. That's
                why we've reimagined the insurance-buying experience, creatinga
                seamless marketplace where protection meets convenience.
              </p>
            </Grid>

            <Grid xs={12}>
              <img
                src="../images/jio-advantage.webp"
                width="100%"
                style={{ borderRadius: "24px" }}
                alt="img"
              />
            </Grid>
            <Grid xs={12}>
              <ul className="listView">
                {whySectionArray?.map((data, index) => (
                  <li key={index}>
                    <img src={data.iconUrl} alt="icon" />
                    <Box>
                      <h5
                        className="mb-1"
                        style={{
                          color: COLORS.darkgrey,
                          fontWeight: "bold",
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                      >
                        {data.heading}
                      </h5>
                      <p
                        style={{
                          color: COLORS.lightgrey,
                        }}
                      >
                        {data.desc}
                      </p>
                    </Box>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
        </Box>

        <Box marginBottom={"48px"} className="blog_section_m">
          <Grid container className="row" columnSpacing={6}>
            <Grid xs={12} marginBottom={"16px"}>
              {/* <h6
              className="mb-3"
              style={{
                color: "#595959",
                fontWeight: 700,
              }}
            >
              Resource Hub
            </h6> */}
              <h3
                style={{
                  fontSize: "24px",
                  lineHeight: "28px",
                  fontWeight: 900,
                }}
                className="mb-4"
              >
                Blogs
              </h3>
              <p
                style={{
                  color: COLORS.lightgrey,
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: 500,
                }}
              >
                At Jio Insurance Broking, we aim to help you make an informed
                decision. Consider following scenarios before selecting an
                insurance policy that suits your needs.
              </p>
            </Grid>
            {/* Slider */}
            <Grid xs={12}>
              <Carousel
                responsive={responsive}
                autoPlay={true}
                swipeable={true}
                draggable={true}
                showDots={true}
                infinite={true}
                partialVisible={false}
                removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                // dotListClass={`custom-dot-list-style ${styles.customDotListStyle}`}
                containerClass={`custom-carousel-track customCarouselTrack`}
                customTransition="transform 0.5s ease-in-out"
              >
                {blogCardsArray?.map((data, index: any) => (
                  <BlogCard data={data} />
                ))}
              </Carousel>
            </Grid>
            <Grid xs={12}>
              <JDSButtons
                text={"Read more blogs"}
                variant={"text"}
                className={"secondaryBtn mt-4"}
                onClick={() => navigate(COMMON_ROUTES.BLOGS)}
              />
            </Grid>
          </Grid>
        </Box>

        <Box marginBottom={"48px"} className="testimonial_section_m">
          <Grid container columnSpacing={6}>
            <Grid xs={12} marginBottom={"12px"}>
              <h3
                style={{
                  fontSize: "24px",
                  lineHeight: "28px",
                  fontWeight: 900,
                }}
              >
                Meet some of our happy customers
              </h3>
            </Grid>
            {/* Slider */}
            <Grid xs={12} paddingLeft={"12px"} paddingRight={"12px"}>
              <Carousel
                responsive={responsive}
                autoPlay={true}
                swipeable={true}
                draggable={true}
                showDots={true}
                infinite={true}
                partialVisible={false}
                removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                // dotListClass={`custom-dot-list-style ${styles.customDotListStyle}`}
                containerClass={`custom-carousel-track customCarouselTrack`}
                customTransition="transform 0.5s ease-in-out"
              >
                {testimonialsSlider?.map((data, index) => (
                  <TestimonialCard data={data} />
                ))}
              </Carousel>
            </Grid>
          </Grid>
        </Box>

        <Box marginBottom={"36px"} className="partners_section_m">
          <Grid container className="row" columnSpacing={6}>
            <Grid xs={12} marginBottom={"16px"}>
              <h3
                style={{
                  fontSize: "24px",
                  lineHeight: "28px",
                  fontWeight: 900,
                }}
              >
                Our partners
              </h3>
            </Grid>
            {/* Slider */}
            <Grid xs={12} className="partners-carousel">
              <Carousel
                responsive={partersLogos}
                autoPlay={true}
                swipeable={true}
                draggable={true}
                showDots={true}
                infinite={true}
                partialVisible={false}
                removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                containerClass={`custom-carousel-track customCarouselTrack`}
                customTransition="transform 0.5s ease-in-out"
              >
                {partnersSlider?.map((data, index) => {
                  return (
                    <Box className="partner_box">
                      <img src={data.imgUrl} alt="" />
                    </Box>
                  );
                })}
              </Carousel>
            </Grid>
          </Grid>
        </Box>

        <Box className="mfaq_section" marginBottom={"24px"}>
          <Grid
            container
            className="row"
            columnSpacing={3}
            marginBottom={"24px"}
          >
            <Grid xs={12}>
              <h3
                style={{
                  fontSize: "24px",
                  lineHeight: "28px",
                  fontWeight: 900,
                }}
              >
                Frequently asked questions
              </h3>
            </Grid>
          </Grid>
          <Grid
            container
            className="row"
            columnSpacing={3}
            justifyContent={"center"}
          >
            <Grid xs={12} className={"mfaqTabs"}>
              <TabContext value={faqtabs}>
                <Box
                  sx={{
                    maxWidth: "800px",
                    borderRadius: "100px",
                    backgroundColor: COLORS.lightbg,
                    padding: "4px",
                    display: "flex",
                  }}
                >
                  <TabList
                    onChange={handleChange}
                    // aria-label="lab API tabs example"
                    className={"faqTablist"}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    allowScrollButtonsMobile
                  >
                    <Tab label="General" value="1" />
                    <Tab label="Two-Wheeler" value="2" />
                    <Tab label="Car" value="3" />
                    <Tab label="Health" value="4" />
                    <Tab label="Term" value="5" />
                    {/* <Tab label="Investment" value="6" /> */}
                  </TabList>
                </Box>
                <Box>
                  <TabPanel value="1">
                    <FAQ data={gifaqs} section_name={faqtabs} />
                  </TabPanel>
                  <TabPanel value="2">
                    <FAQ data={bikefaqs} section_name={faqtabs} />
                  </TabPanel>
                  <TabPanel value="3">
                    <FAQ data={carfaqs} section_name={faqtabs} />
                  </TabPanel>
                  <TabPanel value="4">
                    <FAQ data={healthfaqs} section_name={faqtabs} />
                  </TabPanel>
                  <TabPanel value="5">
                    <FAQ data={termfaqs} section_name={faqtabs} />
                  </TabPanel>
                  <TabPanel value="6">
                    <FAQ data={termfaqs} section_name={faqtabs} />
                  </TabPanel>
                </Box>
              </TabContext>
            </Grid>
            {/* <Grid xs={12}>
            <JDSButtons
              text={"Load more FAQs"}
              variant={"text"}
              className={"secondaryBtn"}
            />
          </Grid> */}
          </Grid>
        </Box>

        <Box className="career_section_m">
          <Grid container className="row" columnSpacing={3}>
            <Grid xs={12}>
              <Box className="career_openingBox">
                <Box marginBottom={"16px"}>
                  <img alt="" src="./images/work-with-us.jpg" width="100%" />
                </Box>
                <Box>
                  <p
                    style={{
                      fontSize: "16px",
                      lineClamp: "24px",
                      fontWeight: "bold",
                      marginBottom: "12px",
                    }}
                  >
                    CAREERS @ JIO
                  </p>
                  <h3
                    style={{ fontSize: "24px", lineHeight: "28px" }}
                    className="mb-4"
                  >
                    Work with us
                  </h3>
                  <h5
                    style={{
                      marginBottom: "24px",
                      color: COLORS.lightgrey,
                    }}
                  >
                    Join us in reshaping the insurance landscape and making a
                    difference in people's lives.
                  </h5>
                  <Box width="184px">
                    <JDSButtons
                      text="Apply now"
                      variant={"text"}
                      className={"primaryBtn"}
                      onClick={() => navigate(COMMON_ROUTES.CAREER)}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* contactlink */}
        <span
          className="contactlink"
          onClick={() => setOpenContactusPopup(true)}
        >
          <img src="./images/contact_advisor_icon_white.svg" alt="icon" />
        </span>

        <MFooter />
      </Box>
    </>
  );
}

export default MHome;
