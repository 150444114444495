import DownloadIcon from "@mui/icons-material/Download";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { Box, Button, Link } from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import NonTermQuotePolicyDetail from "../../../../Page/Desktop/NonTerm/NonTermQuote/NonTermPolicyDetails/NonTermPolicyDetails";
import { formatToCurrency } from "../../../../SupportingFiles/HelpingFunction";
import { COLORS } from "../../../../SupportingFiles/colors";
import JDSButtons from "../../../InputFields/JDSButtons/JDSButtons";

function NonTermQuoteCard({
  quote_data,
  index,
  BUY_ACTION,
}: {
  quote_data: any;
  index: number;
  BUY_ACTION: any;
}) {
  const [openPolicyDetails, setOpenPolicyDetails] = useState<boolean>(false);
  const [openEbi, setOpenEbi] = useState<boolean>(false);

  const download = (value: string) => {
    window.open(value, "_blank");
  };

  console.log(quote_data, "quote_data6");

  // let quote_data = {
  //   buy_online_code: "",
  //   company_code: "L003",
  //   loader: false,
  //   id: 922,
  //   logo: "HDFC-Standard-Life-Insurance-Co-Ltd.svg",
  //   product_code: "NPH002",
  //   quotationDetail: {
  //     premiumDetails: {
  //       maturityBenefitApi: 11094280,
  //       sumAssured: 4973141,
  //       premiumWithGST: 379338,
  //       premium: 363003,
  //       incomeApi: 298570,
  //       appno: "1200025908834",
  //       status: "Success",
  //       maturity_benefit: 11094280,
  //       risk_cover_cal: 4973141,
  //       pay_term: "10",
  //       redirectionUrl: "https://onlineinsuranceuat.hdfclife.com/thirdparty?appnum=1200025908834&mob=9789749874&dob=03/09/2006&email=&mobcd=91",
  //       term: 10,
  //       InvestmentAmount: "363003"
  //     },
  //     productDetails: {
  //       id: 922,
  //       product_code: "NPH002",
  //       product_name: "Sanchay Plus - Long Term Income",
  //       brochure: "",
  //       quote_action: "",
  //       rate_calc_method: "NPH",
  //       tax_calc_method: "TX0001",
  //       proposal_method: "",
  //       discount_calc_method: null,
  //       buy_online_code: null,
  //       policy_covers: "1",
  //       companyProductMap: {
  //         company_code: "L003",
  //         company_id: 210,
  //         product_code: "NPH002",
  //         insurer_pcode: "SANCHPV9",
  //         insurer_name_code: "Long Term Income",
  //         product_type: "SVNG",
  //         insured_product_id: "SANCHPE",
  //         status: 1
  //       }
  //     },
  //     companyDetails: {
  //       company_code: "L003",
  //       name: "HDFC Standard Life Insurance Co. Ltd",
  //       short_desc: "HDFC Life",
  //       logo: "HDFC-Standard-Life-Insurance-Co-Ltd.svg",
  //       claim_ratio: "99.50"
  //     },
  //     specialFeatures: {
  //       id: 1,
  //       productId: 922,
  //       productCode: "NPH002",
  //       summary: "This is a non-participating, non- linked Savings Insurance Plan that offers a credible savings component together with the insurance coverage for your total benefit. With HDFC Life Sanchay Plus, you get to accumulate sufficient fund-reserves for meeting your financial goals and future aspirations.",
  //       featuresBenefits: "<p>Guaranteed Income - Get a guaranteed income for a period of 25 or 30 years after maturity of the plan.</p> <p> Assured benefits: The returns that you get are completely guaranteed and insulated from all kinds of market-risks. </p> <p> Tax benefits under Sections 80C and 10D of Income Tax Act, 1961 </p> <p> Get tax free income to choose guaranteed benefits as regular income payout frequency (Yearly, Half-Yearly, Quarterly and Monthly) </p> <p> There are additional riders that can be availed at the cost of increased premium. </p>",
  //       taxBenefits: "Tax benefits under Sections 80C and 10D of Income Tax Act, 1961.",
  //       deathBenefit: "In case of death of Life Assured during the policy term, the death benefit equal to Sum Assured on Death shall be payable to the nominee. </br> <br> Sum Assured on Death is the highest of: </br> <p> ? 10 times the Annualized Premium, or </p> <p> ? 105% of Total Premiums paid, or </p> <p> ? Premiums paid accumulated at an interest of 5% p.a. compounded annually, or </p> <p> ? Guaranteed Sum Assured on Maturity, or </p> <p> ? An absolute amount assured to be paid on death, which is equal to the Sum Assured </p>",
  //       suicideExclusions: "In case of death due to suicide within 12 months from the date of commencement of risk under the policy or from\nthe date of revival of the policy, as applicable, the nominee or beneficiary of the policyholder shall be entitled to at\nleast 80% of the total premiums paid till the date of death or the surrender value available as on the date of death\nwhichever is higher, provided the policy is in force.",
  //       payFrequency: "Yearly, Half-yearly, Quarterly, Monthly",
  //       policyTerm: "6 & 11 years",
  //       premiumPayingTerm: "5 & 10 years",
  //       minimumEntryAge: "5 years",
  //       maximumEntryAge: "60 years",
  //       minimumMaturityAge: "18 years",
  //       maximumMaturityAge: "71 years",
  //       minimumInstalmentPremium: "<p> Annual: Rs. 30,000 </p>\n<p> Half yearly: Rs. 15,000 </p>\n<p> Quarterly: Rs. 7,500 </p>\n<p>Monthly: Rs. 2,500 </p>"
  //     }
  //   }
  // }

  return (
    <>
      <NonTermQuotePolicyDetail
        open={openPolicyDetails}
        setOpen={setOpenPolicyDetails}
        data={quote_data}
        BUY_ACTION={BUY_ACTION}
        openEbi={openEbi}
        setOpenEbi={setOpenEbi}
      />
      <Modal open={openEbi} onClose={() => setOpenEbi(false)}>
        <Box className="modalWrapper">
          <Box
            sx={{
              transform: "translate(0,0)",
              transition: "trapnsform 0.3s",
              minHeight: "100vh",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box className="modalContent ">
              <Grid className="topbar" container alignItems="center">
                <Grid xs={12} textAlign={"right"}>
                  <Button
                    disableRipple
                    className="closePopup"
                    onClick={() => {
                      setOpenEbi(false);
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} alignContent="flex-start">
                {/* <Grid xs={12} textAlign={"center"}>
                  <iframe
                    src={`https://docs.google.com/viewer?url=${encodeURIComponent(
                      quote_data?.quotationDetail?.premiumDetails?.EBI_PDF_url
                    )}&embedded=true`}
                    style={{
                      minWidth: "500px",
                      width: "100%",
                      height: "700px",
                    }}
                    title="PDF Viewer"
                  ></iframe>
                </Grid> */}
                <Grid
                  xs={12}
                  textAlign={"center"}
                  marginTop={"12px"}
                  display={"flex"}
                  gap={"16px"}
                  justifyContent={"center"}
                >
                  <JDSButtons
                    className="primaryBtn "
                    text="Continue"
                    variant="text"
                    onClick={() => BUY_ACTION(quote_data?.quotationDetail)}
                    fullWidth={false}
                  />
                  <JDSButtons
                    className="primaryBtn "
                    text={
                      <>
                        <DownloadIcon />
                      </>
                    }
                    variant="text"
                    onClick={() => {
                      download(quote_data?.premiumDetails?.EBI_PDF_url);
                    }}
                    fullWidth={false}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Box
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: "12px",
          padding: "16px ",
          boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
          position: "relative",
          marginBottom: "24px",
          zIndex: "9",
        }}
      >
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid
            xs={5}
            display="flex"
            gap="12px"
            flexDirection="row"
            alignItems={"center"}
          >
            <img
              src={quote_data?.quotationDetail?.companyDetails?.logo}
              width="91px"
              height="52px"
            />
            <Box>
              <p>{quote_data?.quotationDetail?.productDetails?.product_name}</p>
              <p>
                <small style={{ color: COLORS.lightgrey }}>
                  {quote_data?.quotationDetail?.companyDetails?.name}
                </small>
              </p>
            </Box>
          </Grid>
          <Grid xs="auto">
            <p>
              <small style={{ color: COLORS.lightgrey }}>Maturity Amount</small>
            </p>
            <h6 style={{ fontWeight: "bold" }}>
              {" "}
              {formatToCurrency(
                quote_data?.quotationDetail?.premiumDetails?.maturity_benefit
              )}
            </h6>
          </Grid>
          <Grid xs="auto">
            <p>
              <small style={{ color: COLORS.lightgrey }}>Risk Cover</small>
            </p>
            <h6 style={{ fontWeight: "bold" }}>
              {formatToCurrency(
                quote_data?.quotationDetail?.premiumDetails?.risk_cover_cal
              )}
            </h6>
          </Grid>

          <Grid xs textAlign="right">
            <Box textAlign="center" display="inline-block">
              <JDSButtons
                className="primaryBtn small"
                text={"Buy Now"}
                variant="text"
                onClick={() => {
                  quote_data?.quotationDetail?.companyDetails?.company_code ==
                  "L004"
                    ? setOpenEbi(true)
                    : BUY_ACTION(quote_data.quotationDetail);
                }}
              />
            </Box>
          </Grid>

          <Grid xs={12}>
            <hr
              style={{
                border: "none",
                borderTop: "1px solid rgba(224, 224, 224, 1)",
              }}
            />
          </Grid>

          <Grid xs={9} display={"flex"} gap={"24px"}>
            <Box>
              <p>
                <small style={{ color: COLORS.lightgrey }}>Policy Term</small>
              </p>
              <h6 style={{ fontWeight: "bold" }}>
                {" "}
                {quote_data?.quotationDetail?.premiumDetails?.term} Years
              </h6>
            </Box>
            <Box>
              <p>
                <small style={{ color: COLORS.lightgrey }}>Policy upto</small>
              </p>
              <h6 style={{ fontWeight: "bold" }}>
                {quote_data?.quotationDetail?.premiumDetails?.pay_term} Years
              </h6>
            </Box>
            <Box>
              <p>
                <small style={{ color: COLORS.lightgrey }}>
                  Max. Maturity Age
                </small>
              </p>
              <h6 style={{ fontWeight: "bold" }}>
                {
                  quote_data?.quotationDetail?.productDetails
                    ?.InvestmentFormValidations?.max_p_cess_age
                }{" "}
                Years
              </h6>
            </Box>
          </Grid>

          <Grid xs={3} display="flex" justifyContent="end">
            <Link
              sx={{
                color: COLORS.darkgold,
                fontWeight: "bold",
                fontSize: "16px",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                setOpenPolicyDetails(true);
              }}
            >
              View Details{" "}
              <KeyboardArrowRightRoundedIcon
                sx={{ height: "20px", width: "20px" }}
              />
            </Link>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default NonTermQuoteCard;
