import { useEffect, useState } from "react";
import ICICNonTermProposal from "../../../../Page/Desktop/NonTerm/NonTermProposal/ICICNonTermProposal";
import { NON_TERM_SERVICES } from "../../../../Services/NonTerm/NonTermFormService";
import { NonTermSlice } from "../../../../Store/Slice_Reducer/NonTerm/NonTermSlice";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import {
  isEmpty,
  validateEmail,
  validateFullName,
  validateMobileNumber,
} from "../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { TDropdown } from "../../../../types/Common/TDropdown";
import { TNonTermProposerData } from "../../../../types/TNonTermSlice";
import MICICNonTermProposal from "../../../../Page/Mobile/NonTerm/MNonTermProposal/MICICNonTermProposal";

const NonTermBasicDetailsContainer = () => {
  const isMobile = useIsMobile();
  const {
    PROPOSER_DATA,
    ADD_FORM,
    ADD_FORM_RESPONSE,
    RIDERS_BENEFITS,
    QUOTE_FIELDS_DATA,
    UPDATE_RESPONSE,
    SELECTED_QUOTE_DATA,
  } = useAppSelector((state) => state.NonTerm);
  const { NonTerm } = useAppSelector((state) => state);
  const [loader, setLoader] = useState<boolean>(false);
  console.log("PROPOSER_DATA", PROPOSER_DATA);
  const dispatch = useAppDispatch();
  const [proposerFields, setProposerFields] =
    useState<TNonTermProposerData>(PROPOSER_DATA);
  const [isProposerSameInsured, setIsProposerSameInsured] =
    useState<boolean>(true);
  const [isChecked, setChecked] = useState<boolean>(true);

  const fieldsUpdateState = (attr: any, value: any) => {
    console.log("attr", attr, value);
    setProposerFields({
      ...proposerFields,
      [attr[0]]: {
        value: value,
        warning:
          attr[0] === "full_name"
            ? !validateFullName(value)
            : attr[0] === "mobile"
              ? !validateMobileNumber(value)
              : attr[0] === "email"
                ? !validateEmail(value)
                : attr[0] === "emergency_number"
                  ? !validateMobileNumber(value)
                  : isEmpty(value),
      },
    });
  };
  const UPDATE_PROPOSER_DETAILS = () => {
    let params = {
      //   quote_no: ADD_FORM_RESPONSE.quote_no,
      //   proposer_name: proposerFields?.full_name?.value,
      //   proposer_mobile: ADD_FORM.mobile.value,
      //   proposer_email: proposerFields?.email?.value,
      //   proposer_gender: ADD_FORM?.gender?.value,
      //   proposer_dob: UPDATE_RESPONSE?.dob
      //     ? UPDATE_RESPONSE?.dob
      //     : ADD_FORM.age.value,
      //   proposer_occupation: proposerFields.occupation.value,
      //   proposer_qualification: proposerFields.qualification.value,
      //   address_pincode: ADD_FORM.pincode.value,
      //   premium: ADD_FORM.investAmount.value,

      //   insured_name: proposerFields?.full_name?.value,
      //   insured_mobile: ADD_FORM.mobile.value,
      //   insured_email: proposerFields.email.value,
      //   insured_gender: ADD_FORM.gender.value,
      //   insured_dob: ADD_FORM.age.value,
      //   insured_occupation: proposerFields.occupation.value,
      //   insured_qualification: proposerFields.qualification.value,
      //   insured_pincode: ADD_FORM.pincode.value,

      quote_no: btoa(ADD_FORM_RESPONSE.quote_no),
      section: "proposer_details",
      details: {
        proposer_annual_income: ADD_FORM?.annualIncome.value,
        proposer_dob: UPDATE_RESPONSE?.dob
          ? UPDATE_RESPONSE?.dob
          : ADD_FORM.age.value,
        proposer_email: proposerFields?.email?.value,
        proposer_emergency_no: proposerFields?.emergency_number.value,
        proposer_gender: ADD_FORM?.gender?.value,
        proposer_mobile: ADD_FORM.mobile.value,
        proposer_name: proposerFields?.full_name?.value,
        proposer_occupation: proposerFields.occupation.value,
        proposer_qualification: proposerFields.qualification.value,
        address_pincode: ADD_FORM.pincode.value,
        insured_name_annual_income: ADD_FORM?.annualIncome.value,
        insured_dob: UPDATE_RESPONSE?.dob
          ? UPDATE_RESPONSE?.dob
          : ADD_FORM.age.value,
        insured_email: proposerFields?.email?.value,
        insured_emergency_no: proposerFields?.emergency_number.value,
        insured_gender: ADD_FORM?.gender?.value,
        insured_mobile: ADD_FORM.mobile.value,
        insured_name: proposerFields?.full_name?.value,
        insured_occupation: proposerFields.occupation.value,
        insured_qualification: proposerFields.qualification.value,
        insured_pincode: ADD_FORM.pincode.value,
      },
    };

    let params2 = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      proposer_name: proposerFields?.full_name?.value,
      proposer_mobile: ADD_FORM.mobile.value,
      proposer_email: proposerFields?.email?.value,
      proposer_gender: ADD_FORM?.gender?.value,
      proposer_dob: UPDATE_RESPONSE?.dob
        ? UPDATE_RESPONSE?.dob
        : ADD_FORM.age.value,
      proposer_occupation: proposerFields.occupation.value,
      proposer_qualification: proposerFields.qualification.value,
      address_pincode: ADD_FORM.pincode.value,
      premium: ADD_FORM.investAmount.value,
      insured_name: proposerFields.insured_name.value,
      insured_mobile: proposerFields.insured_mobile.value,
      insured_email: proposerFields.insured_email.value,
      insured_gender: proposerFields.insured_gender.value,
      insured_dob: proposerFields.insured_dob.value,
      insured_occupation: proposerFields.insured_occupation.value,
      insured_qualification: proposerFields.insured_qualification.value,
      insured_pincode: proposerFields.insured_pincode.value,
    };

    const onSuccess = (res: any) => {
      GET_REDIRECTION_URL();
      console.log("res", res);
      setLoader(false);
    };
    const onError = () => {
      setLoader(false);
    };
    setLoader(true);
    NON_TERM_SERVICES.UPDATE_PROPOSER_DETAILS(
      onSuccess,
      onError,
      isChecked ? params : params2
    );
  };

  const GET_REDIRECTION_URL = () => {

    const onSuccess = (res: any) => {
      dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      console.log("prabh", res);
      setLoader(false);
      const results = res;
      console.log("response", results);
      const error = results.error;
      if (!error) {
        const response = results.response;
        const application_url = response.application_url;
        window.location.href = application_url;
      }
    };
    const onError = () => {
      dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      setLoader(false);
    };

    const params = {
      product_ids: [SELECTED_QUOTE_DATA.productDetails.id],

      quote_no: ADD_FORM_RESPONSE.quote_no,
      accidentalDisSelected:
        RIDERS_BENEFITS.riders_benefits_response.riders?.TF002?.status,
      accidentalDeathSelected:
        RIDERS_BENEFITS.riders_benefits_response.riders?.TF003?.status,
      wop: RIDERS_BENEFITS.riders_benefits_response.riders?.TF004?.status,
    }

    dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    NON_TERM_SERVICES.GET_REDIRECTION_URL(onSuccess, onError, params);
  };

  const validateForm = () => {
    let data = { ...proposerFields };
    console.log("datata", data);
    data = {
      ...data,
      full_name: {
        ...data.full_name,
        warning: !validateFullName(data.full_name.value),
      },
      mobile: {
        ...data.mobile,
        warning: !validateMobileNumber(data.mobile.value),
      },
      emergency_number: {
        ...data.emergency_number,
        warning: !validateMobileNumber(data.emergency_number.value),
      },
      email: {
        ...data.email,
        warning: !validateEmail(data.email.value),
      },
      occupation: {
        ...data.occupation,
        warning: isEmpty(data.occupation.value),
      },
      qualification: {
        ...data.qualification,
        warning: isEmpty(data.qualification.value),
      },

      insured_name: {
        ...data.insured_name,
        warning: isEmpty(data.insured_name.value),
      },
      insured_mobile: {
        ...data.insured_mobile,
        warning: isEmpty(data.insured_mobile.value),
      },
      insured_email: {
        ...data.insured_email,
        warning: isEmpty(data.insured_email.value),
      },
      insured_gender: {
        ...data.insured_gender,
        warning: isEmpty(data.insured_gender.value),
      },
      insured_dob: {
        ...data.insured_dob,
        warning: isEmpty(data.insured_dob.value),
      },
      insured_occupation: {
        ...data.insured_occupation,
        warning: isEmpty(data.insured_occupation.value),
      },
      insured_qualification: {
        ...data.insured_qualification,
        warning: isEmpty(data.insured_qualification.value),
      },
      insured_pincode: {
        ...data.insured_pincode,
        warning: isEmpty(data.insured_pincode.value),
      },
    };
    setProposerFields({ ...data });

    if (isChecked) {
      if (
        !data.full_name.warning &&
        !data.emergency_number.warning &&
        !data.email.warning &&
        !data.occupation.warning &&
        !data.qualification.warning
      ) {
        dispatch(NonTermSlice.actions.UPDATE_PROPOSER_DATA(data));
        UPDATE_PROPOSER_DETAILS();
      }
    } else if (!isChecked) {
      if (
        !data.full_name.warning &&
        !data.emergency_number.warning &&
        !data.email.warning &&
        !data.occupation.warning &&
        !data.qualification.warning &&
        !data.insured_name.warning &&
        !data.insured_mobile.warning &&
        !data.insured_email.warning &&
        !data.insured_gender.warning &&
        !data.insured_dob.warning &&
        !data.insured_occupation.warning &&
        !data.insured_qualification.warning &&
        !data.insured_qualification.warning &&
        !data.insured_pincode.warning
      ) {
        dispatch(NonTermSlice.actions.UPDATE_PROPOSER_DATA(data));
        UPDATE_PROPOSER_DETAILS();
      }
    }
  };

  const NON_TERM_PROPOSER_OCCUPATION_DETAILS = () => {
    let params = {
      product_type_code: "NPAR",
      product_code: `${SELECTED_QUOTE_DATA.productDetails.product_code}`,
    };

    const onSuccess = (res: any) => {
      console.log("res", res);

      const results = res.results;
      const error = results.error;
      const response = results.response;

      let data: TDropdown[] = response.map((item: any) => ({
        label: item.code,
        value: item.title,
      }));

      dispatch(
        NonTermSlice.actions.SET_DROPDOWN_DATA({
          key: "OCCUPATION_DATA",
          value: data,
        })
      );
    };

    const onError = () => { };

    NON_TERM_SERVICES.NON_TERM_PROPOSER_OCCUPATION_DETAILS(
      onSuccess,
      onError,
      params
    );
  };
  const NON_TERM_PROPOSER_QUALIFICATION_DETAILS = () => {
    let params = {
      product_type_code: "NPAR",
      product_code: `${SELECTED_QUOTE_DATA.productDetails.product_code}`,
    };
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;
      const response = results.response;

      let data: TDropdown[] = response.map((item: any) => ({
        label: item.code,
        value: item.title,
      }));

      dispatch(
        NonTermSlice.actions.SET_DROPDOWN_DATA({
          key: "QUALIFICATION_DATA",
          value: data,
        })
      );
    };
    const onError = () => { };
    NON_TERM_SERVICES.NON_TERM_PROPOSER_QUALIFICATION_DETAILS(
      onSuccess,
      onError,
      params
    );
  };

  useEffect(() => {
    NON_TERM_PROPOSER_OCCUPATION_DETAILS();
    NON_TERM_PROPOSER_QUALIFICATION_DETAILS();
  }, []);

  return (
    <>
      {isMobile ? <MICICNonTermProposal
        proposerFields={proposerFields}
        fieldsUpdateState={fieldsUpdateState}
        validateForm={validateForm}
        loader={loader}
        isProposerSameInsured={isProposerSameInsured}
        setIsProposerSameInsured={setIsProposerSameInsured}
        isChecked={isChecked}
        setChecked={setChecked}
      /> : ( // /> //   loader={loader} //   validateForm={validateForm} //   fieldsUpdateState={fieldsUpdateState} //   proposerFields={proposerFields} // <MNonTermProposal
        <ICICNonTermProposal
          proposerFields={proposerFields}
          fieldsUpdateState={fieldsUpdateState}
          validateForm={validateForm}
          loader={loader}
          isProposerSameInsured={isProposerSameInsured}
          setIsProposerSameInsured={setIsProposerSameInsured}
          isChecked={isChecked}
          setChecked={setChecked}
        />
      )}
    </>
  );
};

export default NonTermBasicDetailsContainer;
