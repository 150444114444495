import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import {
  blog_data,
  blogsResponsive,
} from "../../../../Component/Desktop/Common/BlogArray/BLogArray";
import Footer from "../../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../../Component/Desktop/Common/NavBar/NavBar";
import MFooter from "../../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../../Component/Mobile/Common/MNavBar/MNavBar";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import "./../BlogsPage.scss";

const BlogEverythingYouNeed = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const current_blog_data = {
    id: "everything-you-need-to-know-about-personal-accident-cover",
    blogtitle: "Everything you need to know about Personal Accident Cover",
    img_url: "/images/blog2.webp",
    main_desc: (
      <>
        <p className="mb-4">
          Regardless of how unpleasant it sounds; unfortunate events cause all
          happiness to vanish in a couple of seconds. A sudden accident might
          cause you to suffer from physical agony, emotional stress and
          financial burdens when you are wanting to enjoy a good and carefree
          life. In the worst situations, it may result in permanent impairment,
          serious harm, or even death.
        </p>
        <p></p>
      </>
    ),
    blog_full_desc: (
      <>
        <h6 className="mb-3">Why is personal accident insurance essential?</h6>
        <p className="mb-5">
          Personal accident insurance helps you and your family get financial
          assistance in case of an unfortunate event of an accident that leads
          to the insured’s death, bodily injuries, temporary, partial or
          permanent total disability stemming from an accident. Personal
          Accident Insurance policies serve as a barrier and safeguards you
          against such uncertainty.
        </p>

        <h6 className="mb-3">
          How does having a personal accident insurance policy benefit you?
        </h6>
        <ul className="mb-5">
          <li>
            With a personal accident insurance policy, your nominee gets 100% of
            the chosen sum assured against an accidental death or you get 100%
            of sum insured payable for any partial or temporary or permanent
            total disablement.
          </li>
          <li>
            As a financial relief to the patient, it offers a fixed daily
            allowance during hospitalization.
          </li>
          <li>
            Offers multiple benefits at low premiums and there are no medical
            tests needed for purchasing one.
          </li>
          <li>You get worldwide accident coverage.</li>
          <li>You can also cover your entire family under one plan.</li>
        </ul>
        <h6 className="mb-3">
          What are the different kinds of covers available under the personal
          accident insurance?{" "}
        </h6>
        <ul className="mb-5">
          <li>
            <span>Accidental Death Cover:</span> For the family members who are
            financially and emotionally dependent on the insured, an accident
            can be devastating. The nominee named in the policy document
            receives the whole sum promised in the event of death of the
            insured.
          </li>
          <li>
            <span>Permanent / Total Disability Cover:</span> A defined sum
            insured amount is paid to the policyholder if an accident results in
            permanent impairments or lifelong total impairment, such as loss of
            both limbs.
          </li>
          <li>
            <span>Permanent Partial Disability Cover:</span> If physical harm
            results in a permanent partial disability, the insured receives a
            portion (up to 100%) of the benefit.
          </li>
          <li>
            <span>Temporary Total Disability Cover:</span> The insurer will
            offer a weekly payment to make up for the loss of income in the
            event that the insured suffers from temporary total impairments and
            is bedridden. In the event of an income loss, the insured may also
            use this claim amount to cover loan EMI payments.
          </li>
        </ul>
        <h6 className="mb-3">
          What are the major personal accident insurance plans inclusions and
          exclusions?
        </h6>
        <p className="mb-5">
          Before you apply for any insurance policy, it is always to your
          advantage to educate yourself on the terms and conditions. So here is
          a brief on the key inclusions and exclusions that a personal accident
          insurance plan has –
        </p>

        <table className="mb-5 tabledata">
          <thead>
            <tr>
              <th>Inclusions</th>
              <th>Exclusions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Child education support</td>
              <td>Non-allopathic treatments</td>
            </tr>
            <tr>
              <td>Life support benefit</td>
              <td>Influence of intoxicants</td>
            </tr>
            <tr>
              <td>Burns, broken bones and ambulance expenses</td>
              <td>
                Committing a criminal act or being involved in war activities,
                suffering from a mental disorder
              </td>
            </tr>
            <tr>
              <td>Daily allowance</td>
              <td>
                Participating in the naval, military, air force, adventurous or
                sports activities
              </td>
            </tr>
            <tr>
              <td>Accidental dismemberment</td>
              <td>Childbirth or pregnancy</td>
            </tr>
            <tr>
              <td>Accidental death</td>
              <td>Natural death</td>
            </tr>
            <tr>
              <td>Permanent total / partial disability</td>
              <td>Pre-existing disability or injury</td>
            </tr>
            <tr>
              <td>Medical expenses/ hospitalization charges</td>
              <td>Suicide or self-injuries</td>
            </tr>
          </tbody>
        </table>

        <h6 className="mb-3">
          What are the key additional benefits that you can avail with a
          personal accident insurance cover?
        </h6>
        <p className="mb-5">
          There are numerous additional benefits that come with a personal
          accident insurance cover in addition to the four main ones. These
          advantages may be included in the standard plan by some insurers or
          you may purchase them as an add-on cover by paying an additional
          premium:
        </p>
        <ul className="mb-5">
          <li>
            <span>Ambulance Fees:</span> The insured is reimbursed for the cost
            of the ambulance ride to the hospital following the accident.
          </li>
          <li>
            <span>Hospital Daily Expenses:</span> In the event of a
            hospitalization arising out of an accident, the insured is given a
            daily cash allowance. This cover is only available for a specific
            number of days.
          </li>
          <li>
            <span>Broken Bones:</span> The cover provides for a fixed
            compensation in the event that a bone is shattered or otherwise
            damaged.
          </li>
          <li>
            <span>Burns:</span> Accidental burn injuries are also covered by
            insurance.
          </li>
          <li>
            <span>Family Travel Allowance:</span> This benefit is available when
            the hospital is far from the covered person's home. In such cases,
            the actual transportation costs incurred by the insured person's
            close family members are also reimbursed. The sum mentioned in the
            policy will be deducted from the total refund due.
          </li>
          <li>
            <span>Education Advantage:</span> A certain amount is covered in the
            event of the policyholder's untimely death due to an accident for
            the dependent child's educational expenses.
          </li>
          <li>
            <span>Loan Protector:</span> A lump sum payment (as defined in the
            insurance schedule) is made in the case of an untimely death or
            permanent disability arising due to an accident.
          </li>
          <li>
            <span>Transportation or Repatriation of Mortal Remains:</span>
            In the event of the policyholder's death due to an accident, the
            nominee would be reimbursed for any costs associated with the
            repatriation and transportation of the policyholder's mortal remains
            from the scene of the accident to a hospital, residence or
            crematory. Additionally, reimbursement is given for the costs
            related to the religious rites associated with cremation.
          </li>
          <li>
            <span>Adaptation Allowance:</span> This benefit may be provided by
            some insurers. If a tragic occurrence results in total or permanent
            disability or dismemberment, the cost of modifying one's home or
            vehicle is also covered.
          </li>
        </ul>

        <h6 className="mb-3">
          What is compulsory personal accident insurance in motor insurance?
        </h6>
        <p>
          Compulsory personal accident insurance is an add-on cover alongside a
          motor Insurance policy, which covers the owner-driver of an insured
          vehicle against any accidental injuries. It offers financial aid in
          case the owner-driver of the vehicle suffers from bodily injuries,
          disability or death due to the accident.
        </p>
        <p className="mb-5">
          Just as you worry about what would happen to your loved ones in the
          event of your untimely death, you should also consider how they would
          manage in the event of a permanent impairment. With the appropriate
          cover, be ready for any such unforeseen situations. At{" "}
          <span style={{ fontWeight: "900" }}>
            Jio Insurance Broking Limited
          </span>{" "}
          we help you choose the right insurance plan from across multiple
          insurers to safeguard you and your family and also help you understand
          its benefits.
        </p>
      </>
    ),
  };

  return (
    <Box className="blogsWrapper">
      {isMobile ? <MNavBar /> : <NavBar />}
      <Box className="blogsdetail-sec">
        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}>
          <Grid xs={12}>
            <h2>{current_blog_data?.blogtitle}</h2>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              marginBottom={"24px"}>
              <p
                style={{
                  fontSize: "14px",
                  lineHeight: "normal",
                  textTransform: "uppercase",
                  fontWeight: "700",
                }}>
                Sep 30, 2024
              </p>
              <Box display={"flex"} gap={"8px"}>
                <span className="tag">Insurance</span>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}>
          <Grid md={6} marginBottom={"24px"}>
            <Box className="img-overlay">
              <Box className="img-bg">
                <img src={current_blog_data?.img_url} alt="" />
              </Box>
            </Box>
          </Grid>
          <Grid md={6} marginBottom={"24px"}>
            {current_blog_data?.main_desc}
          </Grid>
          <Grid xs={12} marginBottom={"24px"}>
            {current_blog_data?.blog_full_desc}
          </Grid>
        </Grid>
      </Box>
      <Box className="blogCard-sec mb-pr-0">
        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}>
          <Grid xs={12} marginBottom={"24px"} className="mb-pr-0">
            <h4>You May Also Like</h4>
          </Grid>
          <Grid xs={12} className="mb-pr-0">
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={blogsResponsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              arrows={false}
              customTransition="transform 0.7s ease-in-out"
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              partialVisible={true}>
              {blog_data.map((item, index) => (
                <Box>
                  <Box
                    className="blog-card"
                    sx={{
                      boxShadow: "0px 4px 16px rgba(0,0,0,0.08)",
                      borderRadius: "12px",
                      marginRight: "16px",
                    }}>
                    <img src={item?.img_url} alt="" />
                    <Box className="card-info">
                      <h5 className="mb-1">{item.blogtitle}</h5>
                      <p className="mb-3">{item.blog_desc}</p>
                      <Link
                        className="cont-read-link"
                        onClick={() => {
                          navigate(item?.id);
                          window.scrollTo(0, 0);
                        }}>
                        Continue reading
                      </Link>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Carousel>
          </Grid>
        </Grid>
      </Box>
      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default BlogEverythingYouNeed;
