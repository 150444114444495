import moment from "moment";
import { useEffect, useState } from "react";
import { AddTermDTO } from "../../../DTO/TermDTO/AddTermFormDTO";
import TermForm from "../../../Page/Desktop/Term/Form/TermForm";
import { TERM_ROUTES } from "../../../Router/Path/TermRoutes";
import { TERM_SERVICES } from "../../../Services/Term/TermServices";
import { UserProfileSlice } from "../../../Store/Slice_Reducer/Account/UserProfileSlice";
import { TermSlice } from "../../../Store/Slice_Reducer/Term/TermSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import {
  calculateAge,
  detectWebViewEnvironment,
  FORMAT_DD_MM_YYYY,
  FORMAT_YYYY_MM_DD,
  isEmpty,
  validatePincode,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { FRONTEND_DOMAIN } from "../../../URLCollection/Domain";
import { TTermAddForm } from "../../../types/Term/TTermAddForm";
import { toast } from "react-toastify";
import MTermForm from "../../../Page/Mobile/Term/MForm/MTermForm";
import { SESSION_CONSTANTS } from "../../../Services/Constants/SessionConstants";
import { useLocation } from "react-router-dom";

function TermFormContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Function;
}) {
  const isMobile = useIsMobile();
  const [loader, setLoader] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { Term } = useAppSelector((state) => state);

  const [utmMedium, setutmMedium] = useState<string | null>("");
  const [utmSource, setUtmSource] = useState<string | null>("");
  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const utm_medium = queryParams.get("utm_medium");
    const utm_source = queryParams.get("utm_source");
    setutmMedium(utm_medium);
    setUtmSource(utm_source);
  }, []);

  const validateForm = () => {
    let data: TTermAddForm = { ...Term.ADD_FORM };

    data = {
      ...data,
      annualIncome: {
        ...data.annualIncome,
        warning: isEmpty(data.annualIncome.value),
      },
      pincode: {
        ...data.pincode,
        warning: !validatePincode(data.pincode.value),
      },
    };

    if (!data.annualIncome.warning && !data.pincode.warning) {
      add_form(data);
      // EVENT_TRACKER();
    }
  };

  const add_form = (data: TTermAddForm) => {
    const onSuccess = (res: any) => {
      const results = res;
      const response = results?.response;
      const error = results?.error;
      if (!error) {
        // dispatch(TermSlice.actions.UPDATE_ADD_FORM_DATA({ ...data }));
        dispatch(TermSlice.actions.BULK_UPDATE(TermSlice.getInitialState()));
        const quote_no = response?.quote_no;
        if (detectWebViewEnvironment()) {
          sessionStorage.setItem(
            SESSION_CONSTANTS.OPEN_TERM_FORM,
            JSON.stringify(true)
          );
          sessionStorage.setItem(
            SESSION_CONSTANTS.TERM_FORM_DATA,
            JSON.stringify(data)
          );
        }
        window.location.href = `${FRONTEND_DOMAIN}${TERM_ROUTES.QUOTE}?type=TM&quote_no=${quote_no}&redirected=${Term.REDIRECTED}`;
      } else {
        toast.error(`${results?.message}`);
      }

      setLoader(false);
    };
    const onError = (err: any) => {
      console.log("err", err);
      setLoader(false);
    };

    let params = {
      lifeInsurance_module: "TermPlan",
      lifeInsurance_type: "Term Plan",
      termSmoker: data.smoke,
      termAnnualIncome: `${data.annualIncome.value}`,
      termAnnualIncomeOther: `${data.annualIncome.value}`,
      termGender: data.gender.value,
      termDob: `${FORMAT_DD_MM_YYYY(data.age.value)}`,
      termName: data.name.value,
      termMobile: data.mobile.value,
      termEmail: data.email.value,
      termPincode: data.pincode.value,
    };

    setLoader(true);
    TERM_SERVICES.addTermFormData(onSuccess, onError, params);
  };

  useEffect(() => {
    EVENT_TRACKER();
  }, [Term?.ADD_FORM]);

  const EVENT_TRACKER = () => {
    const onSuccess = (res: any) => {};

    const onError = (err: any) => {
      console.log(err);
    };

    const data = {
      mobile_no: Term?.ADD_FORM.mobile.value,
      email: Term?.ADD_FORM.email.value,
      gender: Term?.ADD_FORM.gender.value,
      name: Term?.ADD_FORM.name.value,
      annual_income: Term?.ADD_FORM?.annualIncome.value,
      sum_assured: Term?.ADD_FORM_RESPONSE?.sum_assured,
      pincode: Term?.ADD_FORM?.pincode?.value,
      state: Term.ADD_FORM_RESPONSE?.state,
      city: Term.ADD_FORM_RESPONSE?.city,
      smoker: Term.ADD_FORM?.smoke,
      age: `${calculateAge(Term.ADD_FORM?.age?.value)}`,
      utm_medium: utmMedium || "",
      utm_source: utmSource || "",
      utm_campaign: "",
      utm_keyword: "",
      cron_status: "",
      welcome_sms_status: "No",
      dob: `${FORMAT_YYYY_MM_DD(Term.ADD_FORM?.age?.value)}`,
    };
    TERM_SERVICES.EVENT_TRACKER(onSuccess, onError, data);
  };

  return (
    <>
      {isMobile ? (
        <MTermForm
          open={open}
          setOpen={setOpen}
          validateForm={validateForm}
          loading={loader}
        />
      ) : (
        <TermForm
          open={open}
          setOpen={setOpen}
          validateForm={validateForm}
          loading={loader}
        />
      )}
    </>
  );
}

export default TermFormContainer;
