import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { subYears } from "date-fns";
import { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useLocation, useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { blog_data } from "../../../Component/Desktop/Common/BlogArray/BLogArray";
import BlogCard from "../../../Component/Desktop/Common/BlogCard/BlogCard";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import TestimonialCard from "../../../Component/Desktop/Common/TestimonialCard/TestimonialCard";
import FAQ from "../../../Component/Global/FAQ/FAQ";
import GenderRadio from "../../../Component/Global/GenderRadio/GenderRadio";
import CustomCheckbox from "../../../Component/InputFields/CustomCheckbox/CustomCheckbox";
import DatePicker from "../../../Component/InputFields/DatePicker/DatePicker";
import JDSButtons from "../../../Component/InputFields/JDSButtons/JDSButtons";
import TextInputField from "../../../Component/InputFields/TextInputField/TextInputField";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import TermFormContainer from "../../../Container/Term/Form/TermFormContainer";
import { COMMON_ROUTES } from "../../../Router/Path/CommonRoutes";
import styles from "../../../SCSS/CMSPages.module.scss";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { CarSlice } from "../../../Store/Slice_Reducer/Car/CarSlice";
import { HealthSlice } from "../../../Store/Slice_Reducer/Health/HealthSlice";
import { TermSlice } from "../../../Store/Slice_Reducer/Term/TermSlice";
import { TWSlice } from "../../../Store/Slice_Reducer/TwoWheeler/TWSlice";
import { COLORS } from "../../../SupportingFiles/colors";
import HelmetComponent from "../../../SupportingFiles/HelmetComponent";
import {
  FORMAT_YYYY_MM_DD,
  isEmpty,
  validateEmail,
  validateMobileNumber,
} from "../../../SupportingFiles/HelpingFunction";
import { verifyJwt } from "../../../SupportingFiles/JWTDecoder";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { changeHeaderFooterVisibility } from "../../../SupportingFiles/SendEventToApp";
import { TTermAddForm } from "../../../types/Term/TTermAddForm";
import { pushDataLayer } from "../../../utils/analytics";
import { NonTermSlice } from "../../../Store/Slice_Reducer/NonTerm/NonTermSlice";
import NonTermFormContainer from "../../../Container/Non-term/Form/NonTermFormContainer";
import { TNonTermAddForm } from "../../../types/NonTerm/TNonTermAddForm";
import { getJFSJwtToken } from "../../../SupportingFiles/SendwtAuthToken";
import { TERM_SERVICES } from "../../../Services/Term/TermServices";

function NonTermProductPage() {
  type BlogCard = {
    id: string;
    img_url: string;
    blogtitle: string;
    blog_desc: string;
  };
  const [blogCards, setBlogCards] = useState<BlogCard[]>([]);
  useEffect(() => {
    setBlogCards(blog_data);
  }, []);

  const blogCardsArray = blogCards.slice(0, 3);
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const defaultShow = 5;
  const [showAll, setShowAll] = useState(false);
  const { Term } = useAppSelector((state) => state);

  const whySectionArray = [
    {
      iconUrl: "../images/tax-benefit.svg",
      heading: "Trusted brand: ",
      desc: "Backed by a reliable name, ensuring security and innovative solutions for your financial future.",
    },
    {
      iconUrl: "../images/tax-benefit.svg",
      heading: "Diverse plan options:",
      desc: " A variety of plans to suit different risk appetites and financial objectives. ",
    },
    {
      iconUrl: "../images/tax-benefit.svg",
      heading: "Tax benefits: ",
      desc: "Enjoy potential tax savings on your investment plans, helping you grow your wealth efficiently.",
    },
  ];
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 320 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const testimonialsSlider = [
    {
      review:
        "“Extremely happy. Very satisfied with your express settlement of claims. My experience with you is unique as compared with others. I wish your company a very bright and prosperous future. Thank You!”",
      reviewer_name: "Amit Khandewal",
      location: "Delhi",
    },
    {
      review:
        " “Buying car insurance has never been so easy. It just took me 5 minutes to buy the policy. Very easy and quick. Will recommend it to everyone.”",
      reviewer_name: "Dr. Rupa Jha",
      location: "Mumbai",
    },
    {
      review: `“The multiple options from various Insurers under one roof was helpful and gave me the confidence that I am opting for the right product as required. Excellent support provided by your advisor.”`,
      reviewer_name: "Kunal Sharma",
      location: "Banglore",
    },
    {
      review: `“Renewing my Car insurance from Jio Insurance Broking was a great experience. Overall experience was really good and I was able to complete my transaction in just 5 minutes. I recommend everyone to buy from Jio Insurance Broking Limited.”`,
      reviewer_name: "Jamnadas Bhalani",
      location: "Delhi",
    },
    {
      review: `“Buying ICICI Lombard car insurance from Jio Insurance Broking is great. It just took me 5 minutes to buy a policy online. Very easy and user friendly. Will recommend it to everyone.”`,
      reviewer_name: "Vikas Priyadarshi",
      location: "Indore",
    },
    {
      review: `“The team handled my renewal query and process very smoothly. They also convinced me to go for a personal cover policy which I am happy to opt for. They are a good asset to the team.”`,
      reviewer_name: "Kunal Chavda",
      location: "Chandigarh",
    },
    {
      review: `“The online quote system was simple to use with clear options. The additional options are well laid out and explained on the website, so I knew exactly what I could buy and compare to what I want. Hence renewed my two-wheeler policy online without any trouble. Excellent website”`,
      reviewer_name: "Chirag Lukka",
      location: "Delhi",
    },
    {
      review: `“I renewed my health and motor policy on icicilombard.com and it was a seamless experience. On my previous policy, I had registered a claim last month and I was pleasantly surprised with the claim settlement process. It was extremely hassle free and quick.”`,
      reviewer_name: "Abhishek Gupta",
      location: "Mumbai",
    },
    {
      review: `“Extremely happy. Very satisfied with your express settlement of claims. My experience with you is unique as compared with others. I wish your company a very bright and prosperous future. THANKS ICICI LOMBARD”`,
      reviewer_name: "Leena Vaddella",
      location: "Delhi",
    },
  ];
  const partersLogos = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 320 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const partnersSlider = [
    {
      imgUrl:
        "./images/insurerlogo/Tata-AIA-Life-Insurance-Company-Limited.svg",
    },
    { imgUrl: "./images/insurerlogo/HDFC-Standard-Life-Insurance-Co-Ltd.svg" },
    {
      imgUrl: "./images/insurerlogo/ICICI-Prudential-Life-Insurance-Co-Ltd.svg",
    },
    {
      imgUrl: "./images/insurerlogo/Max-Life-Insurance-Co-Ltd.svg",
    },
  ];
  const typesinvestmentPlansArray = [
    {
      heading: "Low-risk investment plans",
      desc: "Low-risk investment plans include Capital Guarantee Plans for principal protection and modest returns, Savings Plans with insurance benefits, Fixed Deposits for guaranteed returns, PPF with tax benefits, SCSS for seniors, and Savings Bonds. Treasury Bills are very low-risk, and Money Market Funds offer low risk with better returns.",
    },
    {
      heading: "Medium risk investment",
      desc: "Medium-risk investment plans, such as Monthly Income Plans, Hybrid-Debt Oriented Funds, Arbitrage Funds, and ETFs, offer balanced growth with controlled risk. These options blend equity and debt, aiming for steady returns while mitigating extreme market fluctuations. Ideal for investors seeking moderate risk and consistent income.",
    },
    {
      heading: "High-risk investment",
      desc: "High-risk investment plans include ULIPs, mutual funds, stock market investments, IPOs, and cryptocurrencies. These options offer potential for high returns but come with significant volatility. Investors should be prepared for fluctuations and carefully assess their risk tolerance before diving in.",
    },
  ];
  const faqs = [
    {
      title: "What are investment plans?",
      desc: "Investment plans are financial products designed to help individuals grow their wealth over time. They can include a range of options such as ULIP, mutual funds, fixed deposits, stocks, bonds, and insurance policies.",
    },
    {
      title: "What types of investment plans are available in India?",
      desc: (
        <>
          India offers a variety of investment plans, including:
          <ul>
            <li>
              <span>Mutual Funds:</span> Professionally managed investment funds
              that pool money from many investors to purchase securities.
            </li>
            <li>
              <span>Fixed Deposits (FDs):</span> Investment plans where money is
              deposited with a bank or financial institution for a fixed tenure
              at a predetermined interest rate.
            </li>
            <li>
              <span>Stocks:</span> Shares of individual companies traded on
              stock exchanges.
            </li>
            <li>
              <span>Bonds:</span> Debt securities issued by corporations or the
              government to raise capital.
            </li>
            <li>
              <span>Public Provident Fund (PPF):</span> A long-term savings
              scheme with tax benefits, backed by the government.
            </li>
            <li>
              <span>National Pension System (NPS):</span> A retirement savings
              scheme offering tax benefits.
            </li>
            <li>
              <span>Real Estate:</span> Investing in property for rental income
              or capital appreciation.
            </li>
            <li>
              <span>Unit-Linked Insurance Plans (ULIPs):</span> A combination of
              insurance and investment with tax-saving benefits.
            </li>
            <li>
              <span>Endowment Plans:</span> A mix of insurance and savings,
              providing a lump sum after a specific term or in case of death.
            </li>
            <li>
              <span>Money-Back Plans:</span> Insurance policies that provide
              periodic payouts along with insurance coverage.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "What is the difference between a mutual fund and a stock?",
      desc: (
        <>
          <ul>
            <li>
              <span>Mutual Funds:</span> Pooled investments managed by
              professionals, offering diversification across various assets.
            </li>
            <li>
              <span>Stocks:</span> Individual shares of a company's ownership,
              which can be more volatile but offer the potential for higher
              returns.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "How can I start investing in India?",
      desc: (
        <>
          To start investing, you need to:
          <ul>
            <li>Determine your investment goals and risk tolerance.</li>
            <li>Choose the type of investment that aligns with your goals.</li>
            <li>
              Open an account with the relevant financial institution or
              brokerage.
            </li>
            <li>Research and select specific investment options.</li>
            <li>
              Make your initial investment and monitor your portfolio regularly.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "What are the tax benefits of investment plans in India?",
      desc: (
        <>
          Various investment plans offer tax benefits, such as:
          <ul>
            <li>
              <span>Public Provident Fund (PPF):</span> Contributions are
              eligible for tax deduction under Section 80C, and the interest
              earned is tax-free.
            </li>
            <li>
              <span>Equity-Linked Savings Scheme (ELSS):</span> Investments are
              eligible for tax deductions under Section 80C, and long-term
              capital gains are tax-free up to a certain limit.
            </li>
            <li>
              <span>National Pension System (NPS):</span> Contributions are
              eligible for tax deductions under Section 80C and an additional
              deduction for investment up to ₹50,000.
            </li>
            <li>
              <span>Unit Linked Insurance Plans (ULIPs):</span> Premiums paid
              are eligible for tax deduction under Section 80C, and maturity
              proceeds are tax-exempt under Section 10(10D) if the annual
              premium does not exceed 10% of the sum assured.
            </li>
            <li>
              <span>Life Insurance Policies:</span> Premiums paid for life
              insurance policies qualify for tax deductions under Section 80C,
              and the maturity proceeds or death benefit are tax-exempt under
              Section 10(10D), subject to conditions.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "What is the risk associated with different investment plans?",
      desc: (
        <>
          <ul>
            <li>
              <span>Low Risk:</span> Fixed Deposits, PPF
            </li>

            <li>
              <span>Moderate Risk:</span> Mutual Funds, Bonds
            </li>
            <li>
              <span>High Risk</span> : Stocks, Real Estate
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "How do I choose the right investment plan?",
      desc: "Consider factors like your financial goals, risk tolerance, investment horizon, and the potential returns of different investment options. Consulting a financial advisor can also help tailor an investment strategy to your needs.",
    },
    {
      title: "How often should I review my investments?",
      desc: "It is recommended to review your investment portfolio at least annually to ensure it aligns with your financial goals and to make adjustments based on market conditions or life changes.",
    },
    {
      title: "Do investment plans offer guaranteed returns?",
      desc: "Most investment plans don't offer guaranteed returns due to market risks. However, products like Fixed Deposits (FDs), government bonds, and insurance annuities provide more predictable returns. Market-linked investments like stocks and mutual funds offer no guarantees and can fluctuate based on performance.",
    },
    {
      title: "What is the lock-in period under ULIPs?",
      desc: " The lock-in period for Unit Linked Insurance Plans (ULIPs) is 5 years. During this time, you cannot withdraw or redeem the invested funds. However, ULIPs are designed for long-term investment, and staying invested beyond the lock-in period can help maximise potential returns.",
    },
  ];

  const [redirected, setRedirected] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    changeHeaderFooterVisibility(0, () => {});

    getJFSJwtToken()
      .then((res) => {
        const response = JSON.parse(res);
        const token = response?.data?.jwtToken;
        verifyJwt(token).then((result) => {
          if (!isEmpty(result?.payload?.data)) {
            const mobile_no = result?.payload?.data;
            setRedirected(true);

            dispatch(CarSlice.actions.BULK_UPDATE(CarSlice.getInitialState()));
            dispatch(
              HealthSlice.actions.BULK_UPLOAD(HealthSlice.getInitialState())
            );
            dispatch(
              TWSlice.actions.BULK_UPDATE({
                ...TWSlice.getInitialState(),
              })
            );
            dispatch(
              TermSlice.actions.BULK_UPDATE({
                ...TermSlice.getInitialState(),
                REDIRECTED: true,
                ADD_FORM: {
                  ...TermSlice.getInitialState().ADD_FORM,
                  mobile: { value: `${mobile_no.slice(2)}`, warning: false },
                },
              })
            );
          } else {
            setRedirected(false);
            dispatch(
              TermSlice.actions.BULK_UPDATE({
                ...TermSlice.getInitialState(),
              })
            );
          }
        });
      })
      .catch((error) => {
        // toast.error(`Failed to get JWT token: ${error.message}`);
      });
  }, []);

  const [openNonTermForm, setOpenNonTermForm] = useState(false);

  const { NonTerm } = useAppSelector((state) => state);

  const validateNonTerm = () => {
    let data: TNonTermAddForm = { ...NonTerm.ADD_FORM };
    console.log("data", data);
    data = {
      ...data,
      mobile: {
        ...data.mobile,
        warning: !validateMobileNumber(data.mobile.value),
      },
      email: {
        ...data.email,
        warning: !validateEmail(data.email.value),
      },
      age: {
        ...data.age,
        warning: isEmpty(data.age.value),
      },
    };
    dispatch(NonTermSlice.actions.NON_TERM_UPDATE_ADD_FORM_DATA(data));
    if (!data.mobile.warning && !data.age.warning && !data.email.warning) {
      setOpenNonTermForm(true);
      // EVENT_TRACKER();
    }
  };

  const [utmMedium, setutmMedium] = useState<string | null>("");
  const [utmSource, setUtmSource] = useState<string | null>("");
  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const utm_medium = queryParams.get("utm_medium");
    const utm_source = queryParams.get("utm_source");
    setutmMedium(utm_medium);
    setUtmSource(utm_source);
  }, []);

  useEffect(() => {
    EVENT_TRACKER();
  }, []);

  const EVENT_TRACKER = () => {
    const onSuccess = (res: any) => {};

    const onError = (err: any) => {
      console.log(err);
    };

    const data = {
      gender: NonTerm?.ADD_FORM.gender.value,
      mobile_no: NonTerm?.ADD_FORM.mobile.value,
      email: NonTerm?.ADD_FORM.email.value,
      dob: `${FORMAT_YYYY_MM_DD(NonTerm.ADD_FORM?.age?.value)}`,
      insurance_type: "Investment Plan",
      name: NonTerm?.ADD_FORM_RESPONSE.name,
      sum_assured: NonTerm?.ADD_FORM_RESPONSE?.sum_assured,
      pincode: NonTerm?.ADD_FORM?.pincode?.value,
      state: NonTerm.ADD_FORM_RESPONSE?.state,
      city: NonTerm.ADD_FORM_RESPONSE?.city,
      payment_mode: NonTerm.ADD_FORM_RESPONSE?.payment_mode,
      policy_term: NonTerm.ADD_FORM_RESPONSE?.policy_term,
      age: NonTerm.ADD_FORM?.age,
      policy_paying_term: NonTerm.ADD_FORM_RESPONSE?.policy_paying_term,
      utm_medium: utmMedium || "",
      utm_source: utmSource || "",
      utm_campaign: "",
      utm_keyword: "",
      cron_status: "",
    };
    TERM_SERVICES.EVENT_TRACKER_NON_TERM(onSuccess, onError, data);
  };

  return (
    <>
      <HelmetComponent
        title="Investment plans at the best prices | JioInsure"
        description="Buy or renew your Investment Plans. We offer investment plans such as low risk, medium risk, high risk plans | JioInsure"
      />
      <Box className={`${styles.cmsWrapper} ${styles.sectionSpacing}`}>
        {isMobile ? <MNavBar /> : <NavBar />}

        <Box className="heroBanner">
          <img
            alt=""
            src="../images/investment-hero-banner.png"
            width="100%"
            className="bgimg"
          />
          <Box className="innerWrapper">
            <Grid container spacing={3} alignItems="center">
              <Grid
                xs={12}
                md={6}
                display="flex"
                gap="16px"
                flexDirection="column"
                sx={{ color: COLORS.white }}
                className="contentBox"
              >
                <h1>Investment Plans</h1>
                <h2>Invest for growth, along with protection</h2>
                <Box>
                  <p>
                    <img alt="" src="../images/green-tick.svg" width="32px" />
                    Flexible Investment Options
                  </p>
                  <p>
                    <img alt="" src="../images/green-tick.svg" width="32px" />
                    Portfolio Management
                  </p>
                  <p>
                    <img alt="" src="../images/green-tick.svg" width="32px" />
                    High Returns Potential{" "}
                  </p>
                  <p
                    style={{
                      color: "#fff",
                      fontSize: "12px",
                    }}
                  >
                    *Subject to availability
                  </p>
                </Box>
              </Grid>
              <Grid xs={12} md={6}>
                {process.env.REACT_APP_ENV === "DEV" ? (
                  <Box className="formBox" gap="16px!important">
                    <h4
                      style={{
                        marginBottom: "16px",
                        fontSize: "32px",
                        fontWeight: "900",
                        lineHeight: "32px",
                        textAlign: "left",
                        letterSpacing: "-0.96px",
                      }}
                    >
                      Investment + insurance + tax savings in 1 plan
                    </h4>
                    <Box display="flex" flexDirection="column" gap="16px">
                      <Box>
                        <p
                          style={{
                            color: "black",
                            fontWeight: "bold",
                            textAlign: "left",
                            marginBottom: "8px",
                          }}
                        >
                          Select your gender
                        </p>
                        <GenderRadio
                          value={NonTerm.ADD_FORM.gender.value}
                          value_update={(a: string, v: string) => {
                            dispatch(
                              NonTermSlice.actions.NON_TERM_UPDATE_ADD_FORM_DATA(
                                {
                                  ...NonTerm.ADD_FORM,
                                  gender: { value: v, warning: false },
                                }
                              )
                            );
                          }}
                        />
                      </Box>
                      <Box className="mobileNumber">
                        <Box
                          position="absolute"
                          left="12px"
                          bottom="13px"
                          zIndex="2"
                          fontSize="18px"
                          fontWeight="bold"
                          sx={{ color: COLORS.lightgrey }}
                        >
                          +91
                        </Box>
                        <TextInputField
                          title="Enter mobile number"
                          placeholder="XXXXXXXXXX"
                          validation_type="NUMBER"
                          value={NonTerm.ADD_FORM.mobile.value}
                          attrName={"mobile"}
                          value_update={(a: any, v: any) => {
                            dispatch(
                              NonTermSlice.actions.NON_TERM_UPDATE_ADD_FORM_DATA(
                                {
                                  ...NonTerm.ADD_FORM,
                                  mobile: {
                                    value: v,
                                    warning: !validateMobileNumber(v),
                                  },
                                }
                              )
                            );
                          }}
                          warn_status={NonTerm.ADD_FORM.mobile.warning}
                          error_message={
                            isEmpty(NonTerm.ADD_FORM.mobile.value)
                              ? "Enter Mobile Number"
                              : "Enter Valid Mobile Number"
                          }
                          max_length={10}
                        />
                      </Box>
                      <Box>
                        <TextInputField
                          title="Enter your email"
                          placeholder="Eg: abc@abc.com"
                          value={NonTerm.ADD_FORM.email?.value}
                          attrName={"email"}
                          value_update={(a: any, v: any) => {
                            dispatch(
                              NonTermSlice.actions.NON_TERM_UPDATE_ADD_FORM_DATA(
                                {
                                  ...NonTerm.ADD_FORM,
                                  email: {
                                    value: v,
                                    warning: !validateEmail(v),
                                  },
                                }
                              )
                            );
                          }}
                          warn_status={NonTerm.ADD_FORM.email?.warning}
                          error_message={
                            isEmpty(NonTerm.ADD_FORM.email?.value)
                              ? "Enter E-mail"
                              : "Enter valid E-mail"
                          }
                          max_length={50}
                        />
                      </Box>
                      <DatePicker
                        title={"Date of Birth"}
                        value={NonTerm.ADD_FORM.age.value}
                        attrName={"age"}
                        onChange={(a: any, v: any) => {
                          dispatch(
                            NonTermSlice.actions.NON_TERM_UPDATE_ADD_FORM_DATA({
                              ...NonTerm.ADD_FORM,
                              age: { value: v, warning: false },
                            })
                          );
                        }}
                        warn_status={NonTerm.ADD_FORM.age.warning}
                        error_message="Select DOB"
                        min_date={65}
                        max_date={18}
                        default_date={subYears(new Date(), 18)}
                      />
                      <CustomCheckbox
                        label={
                          <>
                            I agree to{" "}
                            <Link
                              style={{
                                color: COLORS.primary,
                                fontWeight: "bold",
                              }}
                              onClick={() => {
                                navigate(COMMON_ROUTES.TERM_AND_CONDTION);
                              }}
                            >
                              Terms & Conditions
                            </Link>
                            ,{" "}
                            <Link
                              style={{
                                color: COLORS.primary,
                                fontWeight: "bold",
                              }}
                              onClick={() => {
                                navigate(COMMON_ROUTES.PRIVACY_POLICY);
                              }}
                            >
                              Privacy Policy
                            </Link>
                            {` and `}
                            <Link
                              style={{
                                color: COLORS.primary,
                                fontWeight: "bold",
                              }}
                              onClick={() => {
                                navigate(COMMON_ROUTES.DISCLAIMER);
                              }}
                            >
                              Disclaimer
                            </Link>
                          </>
                        }
                        attrName="termsandconditions"
                        value={true}
                        defaultChecked={true}
                        variant="large"
                      />
                      <Box width="260px" margin="0px auto">
                        <JDSButtons
                          className="primaryBtn large"
                          text="Get Free Quotes"
                          variant="text"
                          onClick={() => {
                            validateNonTerm();
                          }}
                        />
                        {openNonTermForm ? (
                          <NonTermFormContainer
                            open={openNonTermForm}
                            setOpen={setOpenNonTermForm}
                          />
                        ) : null}
                      </Box>
                    </Box>
                  </Box>
                ) : null}
                {/* <Box className="formBox" gap="16px!important">
                <Box display="flex" flexDirection="column" gap="16px">
                  <Grid container spacing={2} alignItems="flex-start">
                    <Grid xs={12}>
                      <h3>Investment + Insurance + Tax Savings in 1 Plan</h3>
                    </Grid>
                    <Grid xs={12}>
                      <p
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          textAlign: "left",
                          marginBottom: "8px",
                        }}
                      >
                        Select your gender
                      </p>
                      <GenderRadio
                        value={Term.ADD_FORM.gender.value}
                        value_update={(a: string, v: string) => {
                          dispatch(
                            TermSlice.actions.UPDATE_ADD_FORM_DATA({
                              ...Term.ADD_FORM,
                              gender: { value: v, warning: false },
                            })
                          );
                        }}
                      />
                    </Grid>
                    <Grid xs={12}>
                      <Box className="mobileNumber">
                        <Box
                          position="absolute"
                          left="12px"
                          bottom="13px"
                          zIndex="2"
                          fontSize="18px"
                          fontWeight="bold"
                          sx={{ color: COLORS.lightgrey }}
                        >
                          +91
                        </Box>
                        <TextInputField
                          disabled={redirected}
                          title="Enter mobile number"
                          placeholder="XXXXXXXXXX"
                          validation_type="NUMBER"
                          value={Term.ADD_FORM.mobile.value}
                          attrName={"mobile"}
                          value_update={(a: any, v: any) => {
                            dispatch(
                              TermSlice.actions.UPDATE_ADD_FORM_DATA({
                                ...Term.ADD_FORM,
                                mobile: {
                                  value: v,
                                  warning: !validateMobileNumber(v),
                                },
                              })
                            );
                          }}
                          warn_status={Term.ADD_FORM.mobile.warning}
                          error_message={
                            isEmpty(Term.ADD_FORM.mobile.value)
                              ? "Enter mobile number"
                              : "Enter valid mobile number"
                          }
                          max_length={10}
                        />
                      </Box>
                    </Grid>
                    <Grid xs={12}>
                      <DatePicker
                        title={"Date of Birth"}
                        value={Term.ADD_FORM.age.value}
                        attrName={"age"}
                        onChange={(a: any, v: any) => {
                          dispatch(
                            TermSlice.actions.UPDATE_ADD_FORM_DATA({
                              ...Term.ADD_FORM,
                              age: { value: v, warning: false },
                            })
                          );
                        }}
                        warn_status={Term.ADD_FORM.age.warning}
                        error_message="Select DOB"
                        min_date={65}
                        max_date={18}
                        default_date={subYears(new Date(), 18)}
                      />
                    </Grid>
                    <Grid xs={12}>
                      <CustomCheckbox
                        label={
                          <>
                            I agree to{" "}
                            <Link
                              style={{
                                color: COLORS.primary,
                                fontWeight: "bold",
                              }}
                              onClick={() => {
                                navigate(COMMON_ROUTES.TERM_AND_CONDTION);
                              }}
                            >
                              Terms & Conditions
                            </Link>
                            ,{" "}
                            <Link
                              style={{
                                color: COLORS.primary,
                                fontWeight: "bold",
                              }}
                              onClick={() => {
                                navigate(COMMON_ROUTES.PRIVACY_POLICY);
                              }}
                            >
                              Privacy Policy
                            </Link>
                            {` and `}
                            <Link
                              style={{
                                color: COLORS.primary,
                                fontWeight: "bold",
                              }}
                              onClick={() => {
                                navigate(COMMON_ROUTES.DISCLAIMER);
                              }}
                            >
                              Disclaimer
                            </Link>
                          </>
                        }
                        attrName="termsandconditions"
                        value={true}
                        defaultChecked={true}
                        disabled={true}
                        variant="large"
                      />
                    </Grid>
                    <Grid xs={12}>
                      <Box width="260px" margin="8px auto 0px">
                        <JDSButtons
                          className="primaryBtn"
                          text="Get Free Quotes"
                          variant="text"
                          onClick={() => {
                            validateTerm();
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box> */}
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box className="why_jio_section">
          <Grid container className="row" columnSpacing={3} alignItems="center">
            <Grid xs={12}>
              <h2>Why Jio Insurance?</h2>
              <h5
                style={{
                  color: "#595959",
                  maxWidth: "824px",
                }}
              >
                At Jio Insurance Broking, we provide the best investment plans
                with a focus on affordability, flexibility, and high returns.
                Our customer-centric approach, transparent policies, and digital
                support make managing investments simple, while our trusted
                brand ensures long-term financial security.
              </h5>
            </Grid>
            <Grid xs={12} md={6}>
              <img
                src="../images/jio-advantage.webp"
                width="100%"
                style={{ borderRadius: "24px" }}
                alt="img"
              />
            </Grid>
            <Grid xs={12} md={6}>
              <ul className="listView">
                {whySectionArray?.map((data, index) => (
                  <li key={index}>
                    <img src={data.iconUrl} alt="icon" />
                    <Box>
                      <h5
                        className="mb-1 mt-0"
                        style={{
                          color: COLORS.darkgrey,
                          fontWeight: "bold",
                        }}
                      >
                        {data.heading}
                      </h5>
                      <p
                        style={{
                          color: COLORS.lightgrey,
                        }}
                      >
                        {data.desc}
                      </p>
                    </Box>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
        </Box>

        <Grid container className="row" rowSpacing={3} columnSpacing={6}>
          <Grid xs={12} md={6}>
            <h3 className="mb-4 mt-6">What is an investment plan?</h3>
            <p>
              Investment plans are financial strategies designed to build wealth
              over time. They enable you to invest systematically in a variety
              of assets, such as funds and schemes, to meet your financial
              goals. By following a structured approach, these plans help you
              grow your investments and achieve long-term financial objectives.
            </p>
          </Grid>
          <Grid xs={12} md={6}>
            <img
              src="../images/inv_plan_side_image.png"
              style={{ borderRadius: "24px" }}
              width="100%"
              alt=""
            />
          </Grid>
        </Grid>
        <Box className="lightGoldBg">
          <Grid container rowSpacing={3} columnSpacing={6}>
            <Grid xs={12} textAlign="center">
              <h2>Types of investment plans</h2>
            </Grid>
            <Grid xs={12} className="why_jio_section">
              <ul>
                {typesinvestmentPlansArray?.map((data, index) => (
                  <li key={index}>
                    <h4
                      className="mb-2"
                      style={{
                        color: COLORS.darkgrey,
                        fontWeight: "900",
                        lineHeight: "28px",
                        textAlign: "left",
                      }}
                    >
                      {data.heading}
                    </h4>
                    <p
                      style={{
                        color: COLORS.lightgrey,
                        fontSize: "14px",
                        lineHeight: "20px",
                      }}
                    >
                      {data.desc}
                    </p>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
        </Box>

        {/* Testimonial */}
        <Box className="testimonial_section">
          <Grid container className="row" columnSpacing={3}>
            <Grid xs={12}>
              <h2 style={{ maxWidth: "520px", margin: "0px auto" }}>
                Meet some of our happy customers
              </h2>
            </Grid>
          </Grid>
          <Grid container className="row" columnSpacing={0}>
            <Grid xs={12} className="testimonial-carousel">
              <Carousel
                responsive={responsive}
                autoPlay={true}
                swipeable={true}
                draggable={true}
                showDots={true}
                infinite={true}
                partialVisible={false}
                removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                containerClass={`custom-carousel-track customCarouselTrack`}
                customTransition="transform 0.5s ease-in-out"
              >
                {testimonialsSlider.map((data, index) => (
                  <TestimonialCard data={data} />
                ))}
              </Carousel>
            </Grid>
          </Grid>
        </Box>

        {/* Partners */}
        <Box className="partners_section">
          <Grid container columnSpacing={3} marginBottom={"42px"}>
            <Grid xs={12}>
              <h2 style={{ color: "#001E2B" }}>Our Partners</h2>
            </Grid>
          </Grid>
          <Grid container columnSpacing={3} justifyContent={"center"}>
            <Grid xs={12} className="partners-carousel">
              <Carousel
                responsive={partersLogos}
                autoPlay={true}
                swipeable={true}
                draggable={true}
                showDots={true}
                infinite={true}
                partialVisible={false}
                removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                containerClass={`custom-carousel-track customCarouselTrack`}
                customTransition="transform 0.5s ease-in-out"
              >
                {partnersSlider.map((data, index) => {
                  return (
                    <Box className="partner_box">
                      <img src={data.imgUrl} alt="" />
                    </Box>
                  );
                })}
              </Carousel>
            </Grid>
          </Grid>
        </Box>

        {/* faqs */}
        <Grid
          container
          className="row"
          rowSpacing={3}
          columnSpacing={6}
          justifyContent={"center"}
        >
          <Grid xs={12} className="textCenter">
            <h2>Frequently Asked Questions</h2>
          </Grid>
          <Grid xs={12}>
            <FAQ data={showAll ? faqs : faqs.slice(0, defaultShow)} />
          </Grid>
          <Grid xs={12}>
            <JDSButtons
              onClick={() => setShowAll(!showAll)}
              className="secondaryBtn large"
              text={showAll ? "View less" : "View more"}
              variant="outlined"
              fullWidth={false}
              // loader="true"
            />
          </Grid>
        </Grid>

        {isMobile ? <MFooter /> : <Footer />}
      </Box>
    </>
  );
}

export default NonTermProductPage;
