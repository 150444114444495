import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import QuoteNavbar from "../../../../Component/Desktop/Common/QuoteNavbar/QuoteNavbar";
import NonTermQuoteCard from "../../../../Component/Desktop/NonTerm/NonTermQuoteCard/NonTermQuoteCard";
import Loader from "../../../../Component/Global/Loader/Loader";
import JDSButtons from "../../../../Component/InputFields/JDSButtons/JDSButtons";
import SelectDropdown from "../../../../Component/InputFields/SelectDropdown/SelectDropdown";
import TextInputField from "../../../../Component/InputFields/TextInputField/TextInputField";
import { useAppSelector } from "../../../../Store/hooks";
import { COLORS } from "../../../../SupportingFiles/colors";
import {
  calculateAge,
  formatNumberToLakhOrCrores,
} from "../../../../SupportingFiles/HelpingFunction";
import InvestmentReturnCalulator from "./InvestmentReturnCalculator/InvestmentReturnCalulator";
import NonTermModifyDetails from "./NonTermModifyDetails/NonTermModifyDetails";

function NonTermQuote({
  updateMasterState,
  quoteListData,
  applyInvestmentAmount,
  BUY_ACTION,
  payUptoOption,
  minInvestmentAmount,
  maxInvestmentAmount,
  validateInvestmentAmount,
  setQuoteLoader,
  GET_PRODUCT_LIST,
}: {
  quoteListData: any[];
  updateMasterState: Function;
  applyInvestmentAmount: Function;
  BUY_ACTION: any;
  payUptoOption: any;
  minInvestmentAmount: number;
  maxInvestmentAmount: number;
  validateInvestmentAmount: boolean;
  setQuoteLoader: Function;
  GET_PRODUCT_LIST: Function;
}) {
  const {
    ADD_FORM,
    DROPDOWN_DATA,
    QUOTE_LOADER,
    QUOTE_FIELDS_DATA,
    UPDATE_RESPONSE,
  } = useAppSelector((state) => state.NonTerm);
  const [openModifyDetails, setOpenModifyDetails] = useState<boolean>(false);
  const [openInvstReturnCalc, setOpenInvstReturnCalc] =
    useState<boolean>(false);

  useEffect(() => {
    if (QUOTE_LOADER) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [QUOTE_LOADER]);

  return (
    <Box className="quoteWrapper" gap={"24px"}>
      <NonTermModifyDetails
        open={openModifyDetails}
        setOpen={setOpenModifyDetails}
        setQuoteLoader={setQuoteLoader}
        GET_PRODUCT_LIST={GET_PRODUCT_LIST}
      />
      <InvestmentReturnCalulator
        open={openInvstReturnCalc}
        setOpen={setOpenInvstReturnCalc}
      />
      <QuoteNavbar />
      {QUOTE_LOADER ? (
        <Box
          sx={{
            backgroundColor: "rgba(20,20,20, 0.9)",
            height: "100%",
            width: "100%",
            position: "fixed",
            top: "0px",
            zIndex: "991",
            flexDirection: "column",
            gap: "16px",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader size="large" />
            <h5
              style={{
                color: COLORS.white,
                textAlign: "center",
                marginTop: "4px",
              }}
            >
              Fetching best <br /> Insurance Policies for you...
            </h5>
          </Box>
        </Box>
      ) : null}

      <Grid container spacing={3} className="row" alignItems="flex-start">
        <Grid xs={12}>
          <Box
            sx={{
              backgroundColor: "#ffffff",
              borderRadius: "24px",
              padding: "24px 12px",
            }}
          >
            <Grid
              container
              columnSpacing={3}
              rowSpacing={0}
              alignItems="center"
              marginBottom={"16px"}
              className="row"
            >
              {/* Vehicle Info */}
              <Grid xs="auto">
                <Box position="relative" paddingRight="120px">
                  <Box
                    sx={{
                      position: "absolute",
                      right: "0",
                      top: "0px",
                      zIndex: "9",
                    }}
                  >
                    <Link
                      onClick={() => {
                        setOpenModifyDetails(true);
                      }}
                      sx={{
                        color: COLORS.darkgold,
                        fontWeight: 700,
                        padding: "4px 0px",
                        transition:
                          "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        "&:hover": {
                          color: "#DF9F39",
                        },
                      }}
                    >
                      Edit Details
                    </Link>
                  </Box>
                  <h6
                    style={{
                      fontWeight: "bold",
                      marginBottom: "4px",
                    }}
                  >
                    Member Covered
                  </h6>
                  <p style={{ color: "#595959" }}>
                    {UPDATE_RESPONSE?.gender === ""
                      ? ADD_FORM.gender.value === "M"
                        ? "Male"
                        : "Female"
                      : UPDATE_RESPONSE?.gender === "M"
                      ? "Male"
                      : "Female"}
                    ,{" "}
                    {UPDATE_RESPONSE.age == 0
                      ? calculateAge(ADD_FORM.age.value)
                      : UPDATE_RESPONSE.age}
                  </p>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              columnSpacing={3}
              rowSpacing={0}
              alignItems="center"
              className="row"
            >
              <Grid xs={12}>
                <ul style={{ display: "flex", gap: "24px", flexWrap: "wrap" }}>
                  <li
                    style={{
                      flex: 1,
                      position: "relative",
                      minWidth: "170px",
                    }}
                  >
                    <TextInputField
                      class_name=""
                      title="Want to invest"
                      value={ADD_FORM.investAmount.value}
                      value_update={updateMasterState}
                      attrName={"investAmount"}
                      warn_status={false}
                      error_message="Enter amount to invest"
                      max_length={7}
                      alphabet_only={true}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        top: "35px",
                        right: "8px",
                      }}
                    >
                      <JDSButtons
                        className="secondaryBtn small"
                        text="Apply"
                        variant="contained"
                        fullWidth={false}
                        onClick={() => applyInvestmentAmount()}
                      />
                    </Box>
                    {validateInvestmentAmount ? (
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          color: "#EA4559",
                          fontSize: "11px",
                          marginTop: "3px",
                        }}
                      >
                        Enter {minInvestmentAmount} -{" "}
                        {formatNumberToLakhOrCrores(maxInvestmentAmount)}
                      </span>
                    ) : null}
                  </li>
                  <li style={{ flex: 1, minWidth: "170px" }}>
                    <SelectDropdown
                      class_name=""
                      title="Investment Mode"
                      value={ADD_FORM.investmentMode.value}
                      value_update={updateMasterState}
                      attrName={"investmentMode"}
                      data={DROPDOWN_DATA.investmentMode}
                      warn_status={false}
                      error_message="Select Investment Mode"
                    />
                  </li>
                  {ADD_FORM.investmentMode.value === "5" ? null : (
                    <li style={{ flex: 1, minWidth: "170px" }}>
                      <SelectDropdown
                        class_name=""
                        title="Pay upto"
                        value={QUOTE_FIELDS_DATA.payUpto}
                        value_update={updateMasterState}
                        attrName={"payUpto"}
                        data={payUptoOption}
                        warn_status={false}
                        error_message="Select Pay Upto duration"
                      />
                    </li>
                  )}
                  <li style={{ flex: 1, minWidth: "170px" }}>
                    <SelectDropdown
                      class_name=""
                      title="Policy Term"
                      value={QUOTE_FIELDS_DATA.policyTerm}
                      value_update={updateMasterState}
                      attrName={"policyTerm"}
                      data={DROPDOWN_DATA.POLICY_TERM}
                      warn_status={false}
                      error_message="Select Policy Term"
                    />
                  </li>
                  <li style={{ flex: 1, minWidth: "170px" }}>
                    <SelectDropdown
                      class_name=""
                      title="Income Period"
                      value={QUOTE_FIELDS_DATA.incomePeriod}
                      value_update={updateMasterState}
                      attrName={"incomePeriod"}
                      data={DROPDOWN_DATA.INCOME_PERIOD}
                      warn_status={false}
                      error_message="Select Income Period"
                    />
                  </li>
                  <li>
                    <SelectDropdown
                      class_name=""
                      title="Return Type"
                      value={QUOTE_FIELDS_DATA.returnType}
                      value_update={updateMasterState}
                      attrName={"returnType"}
                      data={DROPDOWN_DATA.RETURN_TYPE}
                      warn_status={false}
                      error_message="Select Return Type"
                    />
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {/* Addon Sidebar */}
        <Grid xs sx={{ maxWidth: "308px" }}>
          <Box
            sx={{
              backgroundColor: "#fff",
              borderRadius: "24px",
              padding: "24px",
            }}
          >
            <h6
              style={{
                fontWeight: "900",
                marginBottom: "16px",
                marginTop: "16px",
              }}
            >
              Calculator
            </h6>
            <Box
              sx={{
                background: "rgba(245, 245, 245, 1)",
                borderRadius: "16px",
                padding: "12px",
              }}
            >
              <Link
                sx={{
                  display: "inline-flex",
                  gap: "12px",
                  alignItems: "center",
                }}
                onClick={() => setOpenInvstReturnCalc(true)}
              >
                <img src="./images/calculator_img.svg" alt="" height={"32px"} />
                <h6
                  style={{
                    fontWeight: 700,
                    lineHeight: "24px",
                    color: "rgba(20, 20, 20, 1)",
                  }}
                >
                  Investment Return{" "}
                  <span style={{ display: "block", color: COLORS.darkgold }}>
                    Calculate
                  </span>
                </h6>
              </Link>
            </Box>
          </Box>
        </Grid>
        <Grid container xs spacing={3}>
          <Grid xs={12}>
            {!QUOTE_LOADER ? (
              quoteListData && quoteListData.length > 0 ? (
                quoteListData.map((data: any, index: number) => (
                  <NonTermQuoteCard
                    quote_data={data}
                    index={index}
                    BUY_ACTION={BUY_ACTION}
                  />
                ))
              ) : (
                <Box className="no-quote-box" sx={{ textAlign: "center" }}>
                  <img src="../images/no_quotes_icon.svg" alt="" />
                  <h5 className="my-3">No Matches: Keep Exploring!</h5>
                  <p>Sorry, no results this time. Let's try something else.</p>
                </Box>
              )
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default NonTermQuote;
