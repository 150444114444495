import { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Link,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Unstable_Grid2";
import ContentRadioButton from "../../../../InputFields/ContentRadioButton/ContentRadioButton";
import TextInputField from "../../../../InputFields/TextInputField/TextInputField";
import CustomCheckbox from "../../../../InputFields/CustomCheckbox/CustomCheckbox";
import JDSButtons from "../../../../InputFields/JDSButtons/JDSButtons";
import { COLORS } from "../../../../../SupportingFiles/colors";
import { COMMON_ROUTES } from "../../../../../Router/Path/CommonRoutes";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "../../../../../SupportingFiles/HelpingFunction";

const LoginPopup = ({
  open,
  setOpen,
  showEnterMobileSection,
  setShowEnterMobileSection,
  loginFields,
  setLoginFields,
  fieldsUpdateState,
  handleShowMessage,
  showMessage,
  resendOtp,
  startTime,
  setStartTime,
}: {
  open: boolean;
  setOpen: any;
  showEnterMobileSection: boolean;
  startTime: boolean;
  setShowEnterMobileSection: any;
  loginFields: any;
  setLoginFields: any;
  fieldsUpdateState: Function;
  handleShowMessage: Function;
  showMessage: Function;
  setStartTime: Function;
  resendOtp: Function;
}) => {
  const navigate = useNavigate();
  const [showloginnextStep, setShowloginnextStep] = useState(false);
  const handleCloseLoginPopup = () => {
    setOpen(false);
    setShowloginnextStep(false);
  };
  const [isChecked, setChecked] = useState<boolean>(false);
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event?.target?.checked);
  };

  //  const handleChange = (index, value) => {
  //    // Update the OTP array with the entered value
  //    const newOtp = [...otp];
  //    newOtp[index] = value;
  //    setOtp(newOtp);

  //    // Move focus to the next input field if the current input is not empty
  //    if (
  //      value !== "" &&
  //      index < inputRefs.length - 1 &&
  //      inputRefs[index + 1].current
  //    ) {
  //      inputRefs[index + 1].current.focus();
  //    }
  //  };

  //  useEffect(() => {
  //    // Move focus to the first input field when the component mounts
  //    inputRefs.current[0].current.focus();
  //  }, []);

  const [timer, setTimer] = useState(60);

  // useEffect(() => {
  //   let intervalId: any;

  //   if (timer > 0) {
  //     intervalId = setInterval(() => {
  //       setTimer((prevTimer) => prevTimer - 1);
  //     }, 1000);
  //   } else {
  //     setStartTime(false);
  //   }

  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, [startTime]);

  // useEffect(() => {
  //   let intervalId: NodeJS.Timeout | null = null;

  //   if (startTime && timer > 0) {
  //     intervalId = setInterval(() => {
  //       setTimer((prevTimer) => prevTimer - 1);
  //     }, 1000);
  //   } else if (!startTime || timer <= 0) {
  //     if (intervalId) {
  //       clearInterval(intervalId);
  //     }
  //   }

  //   return () => {
  //     if (intervalId) {
  //       clearInterval(intervalId);
  //     }
  //   };
  // }, [startTime, timer]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;
    if (startTime && timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [startTime, timer, open]);

  const handleResendOtp = () => {
    setLoginFields({
      ...loginFields,
      enterOtp: { value: "", warning: false },
    });
    resendOtp();
    setTimer(60);
    setStartTime(true);
  };

  return (
    <Modal open={open}>
      <Box className="modalWrapper">
        <Box className="modalContent xsWidth">
          <Grid className="topbar" container alignItems="center">
            <Grid xs={12} textAlign={"right"}>
              <Button
                disableRipple
                className="closePopup"
                onClick={() => {
                  setOpen(false);
                  setShowEnterMobileSection(true);
                  setLoginFields({
                    enterMobile: { value: "", warning: false },
                    enterOtp: { value: "", warning: false },
                  });
                  setTimer(60);
                  setStartTime(false);
                }}
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={1} justifyContent={"center"}>
            <Grid xs={12}>
              {showEnterMobileSection && (
                <Box>
                  <h3 className="mb-2">Login with mobile number</h3>
                  <p className="mb-6" style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                    Login to view policy details and renew
                  </p>
                  <Box className="mobileNumber">
                    <Box
                      position="absolute"
                      left="12px"
                      bottom="13px"
                      zIndex="2"
                      fontSize="18px"
                      fontWeight="bold"
                      sx={{ color: COLORS.lightgrey }}
                    >
                      +91
                    </Box>
                    <TextInputField
                      title="Enter mobile number"
                      placeholder="XXXXXXXXXX"
                      value={loginFields.enterMobile.value}
                      // attrName={"mobile"}
                      attrName={[
                        "enterMobile",
                        "value",
                        loginFields,
                        setLoginFields,
                      ]}
                      value_update={fieldsUpdateState}
                      warn_status={loginFields.enterMobile.warning}
                      error_message={
                        isEmpty(loginFields.enterMobile.value)
                          ? "Enter Mobile Number"
                          : "Enter Valid Mobile Number"
                      }
                      max_length={10}
                      validation_type="NUMBER"
                    />
                  </Box>

                  <Box className="mt-6 mb-4">
                    <CustomCheckbox
                      label={
                        <>
                          I agree to{" "}
                          <Link
                            style={{
                              color: COLORS.primary,
                              fontWeight: "bold",
                            }}
                            onClick={() => {
                              navigate(COMMON_ROUTES.TERM_AND_CONDTION);
                            }}
                          >
                            Terms & Conditions
                          </Link>
                        </>
                      }
                      variant="large"
                      attrName="termsandconditions"
                      value={true}
                      defaultChecked={true}
                      disabled={true}
                      value_update={handleCheckboxChange}
                    />
                  </Box>
                  <JDSButtons
                    text={"Generate OTP"}
                    variant={"text"}
                    className={"primaryBtn large"}
                    // onClick={() => setShowloginnextStep(true)}
                    onClick={() => {
                      handleShowMessage();
                      setTimer(60);
                    }}
                  />
                </Box>
              )}

              {/* next step */}
              {!showEnterMobileSection && (
                <Box className="mb-3">
                  <h3 style={{ marginBottom: "16px" }}>OTP Verification</h3>
                  <p
                    className="mb-6 edit_p"
                    style={{ color: "rgba(0, 0, 0, 0.65)" }}
                  >
                    The OTP has been sent to{" "}
                    <span>+91-{loginFields.enterMobile.value}</span>
                    <Link
                      className="edit"
                      // onClick={() => setShowloginnextStep(false)}
                      onClick={() => {
                        setShowEnterMobileSection(true);
                      }}
                    />
                  </p>
                  <TextInputField
                    title="Enter OTP"
                    placeholder="XXXX"
                    validation_type="NUMBER"
                    value={loginFields.enterOtp.value}
                    attrName={[
                      "enterOtp",
                      "value",
                      loginFields,
                      setLoginFields,
                    ]}
                    value_update={fieldsUpdateState}
                    warn_status={loginFields.enterOtp.warning}
                    error_message={
                      isEmpty(loginFields.enterOtp.value)
                        ? "Enter OTP"
                        : "Enter Valid OTP"
                    }
                    max_length={4}
                  />
                  <Box className="otpFieldBox">
                    {/* <input type="text" name="" maxLength={1} placeholder="0" />
                    <input type="text" name="" maxLength={1} placeholder="0" />
                    <input type="text" name="" maxLength={1} placeholder="0" />
                    <input type="text" name="" maxLength={1} placeholder="0" />
                    <input type="text" name="" maxLength={1} placeholder="0" />
                    <input type="text" name="" maxLength={1} placeholder="0" /> */}

                    {/* {otp.map((digit, index) => (
                      <input
                        key={index}
                        type="text"
                        name={`otp-${index}`}
                        maxLength={4}
                        placeholder=""
                        value={digit}
                        onChange={(e) => handleChange(index, e.target.value)}
                        ref={inputRefs[index]}
                      />
                    ))} */}
                  </Box>
                  <JDSButtons
                    text={"Verify OTP"}
                    variant={"text"}
                    className={"primaryBtn large"}
                    onClick={() => {
                      showMessage();
                    }}
                  />
                  {/* <Box className="mt-4" textAlign={"center"}>
                    {timer > 0 ? (
                      <p style={{ color: "#595959" }}>
                        Resend OTP in 00:
                        {timer < 10 ? `0${timer}` : timer}
                      </p>
                    ) : (
                      <p
                        style={{ color: "#595959", cursor: "pointer" }}
                        onClick={() => {
                          setLoginFields({
                            ...loginFields,
                            enterOtp: { value: "", warning: false },
                          });
                          resendOtp();
                        }}
                      >
                        Resend OTP
                      </p>
                    )}
                  </Box> */}
                  {/* <Box className="mt-4" textAlign={"center"}>
                    {timer > 0 ? (
                      <p style={{ color: "#595959" }}>
                        Resend OTP in 00:
                        {timer < 10 ? `0${timer}` : timer}
                      </p>
                    ) : (
                      <p
                        style={{ color: "#595959", cursor: "pointer" }}
                        onClick={() => {
                          setLoginFields({
                            ...loginFields,
                            enterOtp: { value: "", warning: false },
                          });
                          resendOtp();
                          setTimer(30);
                          setStartTime(true);
                        }}
                      >
                        Resend OTP
                      </p>
                    )}
                  </Box> */}
                  {startTime}
                  <Box className="mt-4" textAlign={"center"}>
                    {timer > 0 ? (
                      <p style={{ color: "#595959" }}>
                        Resend OTP in 00:{timer < 10 ? `0${timer}` : timer}
                      </p>
                    ) : (
                      <p
                        style={{ color: "#595959", cursor: "pointer" }}
                        onClick={handleResendOtp}
                      >
                        Resend OTP
                      </p>
                    )}
                  </Box>
                </Box>
              )}
              {/* <p
                style={{ color: "#595959", cursor: "pointer" }}
                onClick={() => {
                  setLoginFields({
                    ...loginFields,
                    enterOtp: { value: "", warning: false },
                  });
                  resendOtp();
                  setTimer(30);
                  setStartTime(true);
                }}
              >
                Resend OTP
              </p> */}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default LoginPopup;
